// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSlice } from '@reduxjs/toolkit';
import {
  UploadingDrawnKmlAction,
  SetCreateKmlAction,
  ResetKmlDrawAction,
  UpdateKmlFeatureAction,
  SetCurrentMeasurementOptionAction,
  SetFirstTimeChangeEstimateAction,
  SetSelectionFeaturesAction,
  SetSelectionPointsAction,
  ResetSelectionAction,
  DeleteKmlFeatureAction,
  SetModeAction,
  SetKmlLineDrawAction,
  ClearDrawnKmlAction,
  SetMeasurementModeAction,
  SetAddToExistingKmlAction,
  SetKmlFeatureAction,
} from './mapDrawActions';

const initialState: IDrawState = {
  selectedFeatures: {
    points: [],
    features: [],
  },
  mode: null,
  measurementMode: false,
  currentMeasurementOption: null,
  firstTimeChangeEstimate: true,
  kmlLine: false,
  createKml: false,
  addToExistingKml: false,
  currentKml: {
    type: 'FeatureCollection',
    features: [],
  },
  uploadingDrawnKml: false,
};

const mapDrawSlice = createSlice({
  name: 'mapDraw',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UploadingDrawnKmlAction, (state, action) => {
        state.uploadingDrawnKml = action.payload;
      })
      .addCase(SetCreateKmlAction, (state, action) => {
        state.createKml = action.payload;
      })
      .addCase(SetAddToExistingKmlAction, (state, action) => {
        state.addToExistingKml = action.payload;
      })
      .addCase(SetModeAction, (state, action) => {
        state.mode = action.payload;
      })
      .addCase(SetSelectionFeaturesAction, (state, action) => {
        state.selectedFeatures.features = action.payload;
      })
      .addCase(SetSelectionPointsAction, (state, action) => {
        state.selectedFeatures.points = action.payload;
      })
      .addCase(ResetSelectionAction, (state) => {
        state.selectedFeatures = initialState.selectedFeatures;
      })
      .addCase(SetMeasurementModeAction, (state, action) => {
        state.measurementMode = !state.measurementMode;
      })
      .addCase(SetCurrentMeasurementOptionAction, (state, action) => {
        state.currentMeasurementOption = action.payload;
      })
      .addCase(SetFirstTimeChangeEstimateAction, (state, action) => {
        state.firstTimeChangeEstimate = action.payload;
      })
      .addCase(DeleteKmlFeatureAction, (state, action) => {
        const index = state.currentKml.features.findIndex((feature: any) => feature.id === action.payload.id);
        if (index !== -1) state.currentKml.features.splice(index, 1);
      })
      .addCase(UpdateKmlFeatureAction, (state, action) => {
        const index = state.currentKml.features.findIndex((feature: any) => feature.id === action.payload.id);
        if (index !== -1) {
          state.currentKml.features[index] = action.payload;
        } else {
          state.currentKml.features.push(action.payload);
        }
      })
      .addCase(SetKmlFeatureAction, (state, action) => {
        state.currentKml = action.payload;
      })
      .addCase(ResetKmlDrawAction, (state) => {
        state.createKml = initialState.createKml;
        state.currentKml = initialState.currentKml;
      })
      .addCase(SetKmlLineDrawAction, (state, action) => {
        state.kmlLine = action.payload;
      })
      .addCase(ClearDrawnKmlAction, (state) => {
        state.currentKml = initialState.currentKml;
      });
  },
});

export const { reducer } = mapDrawSlice;

export default reducer;
