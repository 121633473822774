// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, useTheme } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton, Typography } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import moment from 'moment';
import WarningDeliverableDialog from 'Components/WarningDeliverableDialog';
import UploadFilesDialog from 'Components/UploadFilesDialog';
import CreditOverageDialog from 'Components/CreditOverageDialog';
import { deliverableCost, projByProjDollarPerAcre } from 'Utils/deliverables';
import { pricingDescription, rasterizationCredits, utilitiesPackagesSubtypes } from 'Utils/constants';
import ConfirmProceedDialog from 'Components/ConfirmProceedDialog';
import ErrorDialog from 'Components/ErrorDialog';
import { GetTemplatesRoute } from 'Utils/routes';
import { GetGlobalStatusThunk, GetOpsCreditCapacityThunk } from 'Features/globalStatus/globalStatusThunk';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { GetCreditCardsThunk } from 'Features/account/subscriptionThunk';
import { GetOrgProfileThunk } from 'Features/account/accountThunk';
import { useSelector } from 'react-redux';
import { RootState } from 'Store';
import { GetBundlesThunk } from 'Features/bundle/bundleThunk';
import { ResetBundleStateAction } from 'Features/bundle/bundleActions';
import { selectProjectEPSGCode, selectProjectFiles } from 'Features/project/projectSelectors';
import { GetProjectEpsgCodeThunk } from 'Features/project/projectThunk';
import { selectAvailableTeams } from 'Features/account/accountSelectors';
import { SetPlaceOrderSuccessAction } from '../orderActions';
import { GetOrderDollarCostThunk, PlaceOrderThunk, SaveEstimateThunk } from '../orderThunk';
import { getOrders } from '../orderSelectors';
import Carousel3DFE from './Carousel3DFE';
import BillingColumn from './BillingColumn';
import ConfirmColumn from './ConfirmColumn';
import Package from './Package';
import MapColumn from './MapColumn';
import stylesDef from './styles';

const twoDecimals = (number: number) => +number.toFixed(2);

interface OrderFormProps {
  orderFormVisible: boolean;
  setOrderFormVisible: (show: boolean) => void;
}

const OrderForm = ({ orderFormVisible, setOrderFormVisible }: OrderFormProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { activeOrder } = useSelector((state: RootState) => getOrders(state));
  const files = useSelector((state: RootState) => selectProjectFiles(state));
  const { availableTeams } = useSelector((state: RootState) => selectAvailableTeams(state));
  const { tifEpsg, lasEpsg, epsgMatch } = useSelector((state: RootState) => selectProjectEPSGCode(state));
  const { topoMajr, topoMinr, density, deliveryDate, bundleDeliverables, selectedBundle, selectedAdditionals, totalCost } = useAppSelector(
    (state: RootState) => state.bundle,
  );
  const { project, fileStatusJson, rasterizationSelected } = useAppSelector(
    (state: RootState) => state.project,
  );
  const { org } = useAppSelector(
    (state: RootState) => state.account,
  );
  const { placeOrderSuccess } = useAppSelector(
    (state) => state.order,
  );

  const [bundleType, setBundleType] = useState(project?.type || 'standard');
  const [bundleSubtype, setBundleSubtype] = useState(project?.type === 'utilities' ? utilitiesPackagesSubtypes[0] : '');
  const lowDensity = density === 'low';
  const { imageryExtraDollarCost, adjustedOrderAcres, imageryCreditCost, bundleId: selectedBundleId } = selectedBundle || {};
  const rasterizationCreditCost = rasterizationSelected ? rasterizationCredits : 0;
  const { availableCreditAcreage, stripeId } = org;
  const pricingPlanName = org?.currentPricingPlanInfo?.name;
  const expressAIBundle = selectedBundleId === 9;

  useEffect(() => {
    const loadData = async () => {
      await dispatch(GetOpsCreditCapacityThunk());
      await dispatch(GetGlobalStatusThunk());
      await dispatch(GetCreditCardsThunk(stripeId));
      await dispatch(GetOrgProfileThunk());

      // Get Deliverables
      const { isEstimate } = activeOrder;
      let selectedBundleSubtype = bundleSubtype;
      if (isEstimate) {
        selectedBundleSubtype = activeOrder.bundleSubtype;
        setBundleSubtype(selectedBundleSubtype);
        dispatch(SaveEstimateThunk(activeOrder.cadTemplate, null, activeOrder, null, pricePerFoot, true, false));
      }
      if (!selectedBundle) {
        const { deliverySpeed } = await dispatch(GetBundlesThunk(activeOrder._id, project._id, project.type, selectedBundleSubtype, isEstimate));
        setDeliverySpeed(deliverySpeed);
      }
    };

    loadData();
    return () => {
      setFileDepenciesExist(false);
      dispatch(ResetBundleStateAction());
      resetDollarCostValues();
      setPage(0);
    };
  }, []);

  const [page, setPage] = useState(0);
  const [cards, setCards] = useState(false);
  const [cadTemplate, setCadTemplate] = useState('');
  const [showTemplateReviewDialog, setShowTemplateReviewDialog] = useState(false);
  // checkedForOverage is set to true only after overage related checks are done, so that the template review dialog is displayed only after the overage dialog
  const [checkedForOverage, setCheckedForOverage] = useState(false);
  // allowTemplateReview is set to false while the overage dialog is displayed, and true other times
  const [allowTemplateReview, setAllowTemplateReview] = useState(true);

  const reviewCadTemplate = () => {
    navigate(GetTemplatesRoute(cadTemplate));
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [deliverySpeed, setDeliverySpeed] = useState('normal');
  const [currentOrderAcreage, setCurrentOrderAcreage] = useState(0);
  const [currentOrderLength, setCurrentOrderLength] = useState(0);
  const [middlePoint, setMiddlePoint] = useState('');
  const [referenceId, setReferenceId] = useState('');
  const [team, setTeam] = useState('');
  const [coordinates, setCoordinates] = useState('');
  const { isEstimate } = activeOrder;
  const [fileDepenciesExist, setFileDepenciesExist] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [uploadFilesFormVisible, setUploadFilesFormVisible] = useState(false);

  const tifMissing = !project.tifFiles || project.tifFiles.length === 0;
  const lasMissing = !project.lasFiles || project.lasFiles.length === 0;
  const [bundleStep, setBundleStep] = useState(0);

  const [tifDependencies, setTifDependencies] = useState([]);
  const [lasDependencies, setLasDependencies] = useState([]);

  const [pricingInfo, setPricingInfo] = useState(null);
  const [totalCredits, setTotalCredits] = useState(0);
  const [totalDollar, setTotalDollar] = useState(0);
  const [pricePerFoot, setPricePerFoot] = useState(0 || activeOrder?.pricePerFoot);
  const [totalTax, setTotalTax] = useState(0);
  const [creditsToMin, setCreditsToMin] = useState(0);
  const [inLicenseAddonAcres, setinLicenseAddonAcres] = useState(0);
  const [additonalCharges, setAdditonalCharges] = useState(0);
  const [overageInfo, setOverageInfo] = useState(null);

  const [showCreditOverageDialog, setShowCreditOverageDialog] = useState(false);
  const [creditOverageAcknowledged, setCreditOverageAcknowledged] = useState(false);
  const [contactSalesInteraction, setContactSalesInteraction] = useState(false);

  const [addOnLayerAcres, setAddOnLayerAcres] = useState(0);
  const [addOnLayerDollars, setAddOnLayerDollars] = useState(0);
  const [addOnDeliverableAcres, setAddOnDeliverableAcres] = useState(0);
  const [addOnDeliverableDollars, setAddOnDeliverableDollars] = useState(0);

  const [showStillProcessingDialog, setShowStillProcessingDialog] = useState(false);
  const [showEpsgMismatchDialog, setShowEpsgMismatchDialog] = useState(false);
  const [epsgMismatchAcknowledged, setEpsgMismatchAcknowledged] = useState(false);

  const [showErrorsDialog, setShowErrorsDialog] = useState(false);
  const [errorsExist, setErrorsExist] = useState(false);
  const [showChangeWarning, setShowChangeWarning] = useState(false);
  const [show3DFECarousel, setShow3DFECarousel] = useState(false);
  const [carouselDismissed, setCarouselDismissed] = useState(false);

  const [firstTimeChangeEstimate, setFirstTimeChangeEstimate] = useState(activeOrder.isEstimate);
  const [next, setNext] = useState(null);

  const checkFileDependenciesAndErrors = async () => {
    const _tif: string[] = [];
    const _las: string[] = [];
    let tifRequired = false;
    let lasRequired = false;

    if (selectedAdditionals.findIndex((addOn) => addOn.requirements.includes('0')) > -1) {
      tifRequired = true;
    }

    if (selectedAdditionals.findIndex((addOn) => addOn.requirements.includes('1')) > -1) {
      lasRequired = true;
    }

    if (!tifRequired && bundleDeliverables.findIndex((del) => del.requirements.includes('0')) > -1) {
      tifRequired = true;
    }

    if (!lasRequired && bundleDeliverables.findIndex((del) => del.requirements.includes('1')) > -1) {
      lasRequired = true;
    }

    selectedAdditionals.forEach((del) => {
      del.requirements.forEach((req) => {
        // The constants 0, 1 are enumerations that are stored in src/common_variables.js on aw-server. Circa 2021-02-22
        if (req === '0' && tifMissing) {
          _tif.push(del.name);
        }
        if (req === '1' && lasMissing) {
          _las.push(del.name);
        }
      });
    });

    bundleDeliverables.forEach((del) => {
      del.requirements.forEach((req) => {
        // The constants 0, 1 are enumerations that are stored in src/common_variables.js on aw-server. Circa 2021-02-22
        if (req === '0' && tifMissing) {
          _tif.push(del.name);
        }
        if (req === '1' && lasMissing) {
          _las.push(del.name);
        }
      });
    });

    setTifDependencies(_tif);
    setLasDependencies(_las);

    let errors = errorsExist;

    files.forEach((file) => {
      if (fileStatusJson && fileStatusJson[file.name] && fileStatusJson[file.name].status === 'ERROR') {
        errors = errors || true;
      }
    });

    if (_tif.length > 0 || _las.length > 0) {
      setShowConfirmDialog(true);
      setFileDepenciesExist(true);
      return;
    }
    if (errors) {
      setShowErrorsDialog(true);
      setErrorsExist(true);
      return;
    }

    if ((tifRequired && !tifEpsg) || (lasRequired && !lasEpsg)) {
      const { tifWithEpsg, lasWithEpsg } = await dispatch(GetProjectEpsgCodeThunk(project._id));
      if (tifRequired && !tifWithEpsg) {
        setShowStillProcessingDialog(true);
        return;
      }
      if (lasRequired && !lasWithEpsg) {
        setShowStillProcessingDialog(true);
        return;
      }
    }

    if ((tifRequired && tifEpsg) && (lasRequired && lasEpsg) && !epsgMatch && !epsgMismatchAcknowledged) {
      setShowEpsgMismatchDialog(true);
      return;
    }

    let orderDollarCostResult: any = {};
    if (pricingPlanName) {
      orderDollarCostResult = await dispatch(GetOrderDollarCostThunk(totalCredits, true));
      if (orderDollarCostResult.overageInfo) {
        setOverageInfo(orderDollarCostResult.overageInfo);
      }
    }

    if (!expressAIBundle && !fileDepenciesExist && !errorsExist && (pricingPlanName && !!orderDollarCostResult?.overageInfo && !creditOverageAcknowledged)) {
      setShowCreditOverageDialog(true);
      const pricingDisplayName = pricingDescription.find((plan) => plan.name === pricingPlanName)?.displayName;
      window?.pendo?.track(`${pricingDisplayName} upsell`, { projectId: project?._id });
      return;
    }

    if (!fileDepenciesExist && !errorsExist) {
      if (pricingPlanName === 'enterprise_pre_commit') {
        setPage(page + 2);
      } else if (!expressAIBundle && pricingPlanName === 'no_commit') {
        setPage(page + 1);
      } else if (!expressAIBundle && pricingPlanName) {
        // pre-pay
        if (additonalCharges > 0) {
          setPage(page + 1);
        } else {
          setPage(page + 2);
        }
      } else {
        const charges = +totalCost + +addOnDeliverableDollars + +addOnLayerDollars + +imageryExtraDollarCost;
        if (charges > 0) {
          setPage(page + 1);
        } else {
          if (selectedBundle.bundleId === 4 && !carouselDismissed) {
            setShow3DFECarousel(true);
          }
          setPage(page + 2);
        }
      }
    }
  };

  const zeroAddOns = () => {
    setAddOnLayerDollars(0);
    setAddOnLayerAcres(0);
    setAddOnDeliverableDollars(0);
    setAddOnDeliverableAcres(0);
    setinLicenseAddonAcres(0);
  };

  const saveEstimate = async () => {
    const additionalDeliverables = selectedAdditionals.map((del) => del.name);
    const { anticipatedKickoffDate } = activeOrder;
    let anticipatedKickoffDateFormatted;
    if (anticipatedKickoffDate) {
      anticipatedKickoffDateFormatted = moment.utc(anticipatedKickoffDate).format('MM-DD-YYYY');
    }

    if (project?.imageryRequest) {
      window?.pendo?.track('Imagery Requested with estimate', { projectId: project?._id });
    }
    window?.pendo?.track('Save Estimate', {
      projectId: project?._id,
      bundle: selectedBundle.name,
      addOns: additionalDeliverables,
      topoIntervals: `major: ${topoMajr || 'N/A'}, minor: ${topoMinr || 'N/A'}`,
      density: selectedBundle.density,
      ...(anticipatedKickoffDate && { kickoff_date: anticipatedKickoffDateFormatted }),
    });
    setIsSaving(true);
    await dispatch(SaveEstimateThunk(cadTemplate, deliveryDate, undefined, bundleType, pricePerFoot, true, true));
    setIsSaved(true);
    setIsSaving(false);
    setFirstTimeChangeEstimate(true);
  };

  const setInitialAddOns = (selectAdditionals?: any) => {
    let remainingAvailableCreditAcreage = selectedBundle && (availableCreditAcreage - selectedBundle.adjustedOrderAcres - rasterizationCreditCost);
    let startingAddOns: any;
    if (selectAdditionals?.length && !(isEstimate && firstTimeChangeEstimate)) {
      startingAddOns = selectAdditionals.map((additional: { _id: any; type: any; name: string, typeFactor: number[], is3D: boolean }) => (
        {
          id: additional._id,
          addOnType: additional.type,
          typeFactor: additional.is3D ? additional.typeFactor[1] : additional.typeFactor[0],
          name: additional.name,
        }));
    } else {
      startingAddOns = activeOrder.addOns;
    }

    const deliverySpeedCreditFactor = selectedBundle?.deliverySpeedAdjustmentFactor.find((speedObj) => speedObj.speed === deliverySpeed)?.creditFactor;
    let startingLayerDollars = 0;
    let startingDeliverableDollars = 0;
    let startingLayerAcres = 0;
    let startingDeliverableAcres = 0;

    for (let i = 0; i < startingAddOns.length; i += 1) {
      let addOnAcreCost = 0;
      const addOn = startingAddOns[i];
      if (isEstimate && firstTimeChangeEstimate) {
        addOnAcreCost = addOn.acres;
      } else {
        addOnAcreCost = deliverableCost(addOn.typeFactor, activeOrder.acreage);
        if (addOn.name !== 'Breaklines Powered by Datasight') {
          addOnAcreCost = +(addOnAcreCost * deliverySpeedCreditFactor).toFixed(2);
        }
        if (selectedBundleId === 5 && lowDensity) {
          addOnAcreCost = +(addOnAcreCost * 0.6).toFixed(2);
        }
      }

      if (pricingPlanName) {
        if (addOn.addOnType === 'layer') {
          startingLayerAcres += addOnAcreCost;
        } else {
          startingDeliverableAcres += addOnAcreCost;
        }
      } else if (remainingAvailableCreditAcreage <= 0) {
        if (addOn.addOnType === 'layer') {
          startingLayerDollars += twoDecimals(+addOnAcreCost * +projByProjDollarPerAcre);
        } else {
          startingDeliverableDollars += twoDecimals(+addOnAcreCost * +projByProjDollarPerAcre);
        }
      } else if (remainingAvailableCreditAcreage >= addOnAcreCost) {
        if (addOn.addOnType === 'layer') {
          startingLayerAcres += addOnAcreCost;
        } else {
          startingDeliverableAcres += addOnAcreCost;
        }
        remainingAvailableCreditAcreage -= addOnAcreCost;
      } else {
        const extraDollars = twoDecimals((addOnAcreCost - remainingAvailableCreditAcreage) * +projByProjDollarPerAcre);
        if (addOn.addOnType === 'layer') {
          startingLayerDollars += extraDollars;
          startingLayerAcres += remainingAvailableCreditAcreage;
        } else {
          startingDeliverableDollars += extraDollars;
          startingDeliverableAcres += remainingAvailableCreditAcreage;
        }
        remainingAvailableCreditAcreage = 0;
      }
    }

    setAddOnLayerDollars(twoDecimals(startingLayerDollars));
    setAddOnLayerAcres(twoDecimals(startingLayerAcres));
    setAddOnDeliverableDollars(twoDecimals(startingDeliverableDollars));
    setAddOnDeliverableAcres(twoDecimals(startingDeliverableAcres));
  };

  useEffect(() => {
    setInitialAddOns(null);
  }, []);

  useEffect(() => {
    const { addOns } = activeOrder;
    // Initial add-ons when delivery speed and density get changed in order form and
    // for saved estimate
    if (
      (selectedAdditionals?.length > 0) ||
      (selectedAdditionals?.length === addOns?.length && isEstimate && firstTimeChangeEstimate)
    ) {
      setInitialAddOns(selectedAdditionals);
    }
  }, [selectedBundle, deliverySpeed, lowDensity, selectedAdditionals]);

  const manageAddOns = (deliverableId: number, type: string, typeFactor: number, checked: boolean, typeFactor3D?: boolean) => {
    const addOrSubtract = checked ? 1 : -1;
    const deliverySpeedCreditFactor = selectedBundle?.deliverySpeedAdjustmentFactor.find((speedObj) => speedObj.speed === deliverySpeed)?.creditFactor;
    let preAddOnAcreCost = deliverableCost(typeFactor, activeOrder.acreage);
    if (deliverableId !== 53) {
      preAddOnAcreCost = +(preAddOnAcreCost * deliverySpeedCreditFactor).toFixed(2);
    }
    if (selectedBundleId === 5 && lowDensity) {
      preAddOnAcreCost = +(preAddOnAcreCost * 0.6).toFixed(2);
    }

    if (pricingPlanName) {
      // For new pricing plan, we count all add on credits as in license credits
      if (type === 'layer') {
        setAddOnLayerAcres((prev) => prev + twoDecimals(+preAddOnAcreCost * +addOrSubtract));
      } else {
        setAddOnDeliverableAcres(addOnDeliverableAcres + twoDecimals(+preAddOnAcreCost * +addOrSubtract));
      }
      return;
    }

    // Cost of order with all addOns and new addOn (add or subtract)
    const figureOutAcres = twoDecimals(+adjustedOrderAcres + rasterizationCreditCost + +imageryCreditCost + (+preAddOnAcreCost * addOrSubtract) + +addOnDeliverableAcres + +addOnLayerAcres);
    const adjustedAvailableCreditAcreage = twoDecimals(+availableCreditAcreage - +figureOutAcres);

    if (adjustedAvailableCreditAcreage <= 0) {
      const creditOverage = adjustedAvailableCreditAcreage * -1;

      if (preAddOnAcreCost > creditOverage) {
        const projByProjDollars = twoDecimals(+creditOverage * +projByProjDollarPerAcre * +addOrSubtract);

        const creditRemainder = preAddOnAcreCost - creditOverage;
        if (type === 'layer') {
          setAddOnLayerDollars(addOnLayerDollars + projByProjDollars);
          setAddOnLayerAcres(addOnLayerAcres + twoDecimals(+creditRemainder * +addOrSubtract));
        } else {
          setAddOnDeliverableDollars(addOnDeliverableDollars + projByProjDollars);
          setAddOnDeliverableAcres(addOnDeliverableAcres + twoDecimals(+creditRemainder * +addOrSubtract));
        }
      } else {
        const projByProjDollars = twoDecimals(+preAddOnAcreCost * +projByProjDollarPerAcre * +addOrSubtract);

        if (type === 'layer') {
          setAddOnLayerDollars(addOnLayerDollars + projByProjDollars);
        } else {
          setAddOnDeliverableDollars(addOnDeliverableDollars + projByProjDollars);
        }
      }
    } else {
      let extraAcres = 0;

      let inLicenseAcres = 0;
      if (adjustedAvailableCreditAcreage + preAddOnAcreCost >= 0) {
        inLicenseAcres = preAddOnAcreCost;
      } else {
        inLicenseAcres = adjustedAvailableCreditAcreage - availableCreditAcreage;
        extraAcres = preAddOnAcreCost - inLicenseAcres;
      }
      // dollar cost of addOn
      const preCost = twoDecimals(+preAddOnAcreCost * +projByProjDollarPerAcre);

      if (type === 'layer') {
        if (addOnLayerDollars > 0 && !checked && inLicenseAcres > 0) {
          // If preCost is less than existing addOnLayerDollars, then the whole dollar cost can be taken from addOnLayerDollars
          if (preCost < addOnLayerDollars) {
            setAddOnLayerDollars(addOnLayerDollars - preCost);
          } else {
            // otherwise set addOnLayerDollars to 0
            setAddOnLayerDollars(0);

            // calculate the acre cost of the remaining dolalrs
            const overAcres = twoDecimals(+addOnLayerDollars / +projByProjDollarPerAcre);

            // subtract the difference from the existing addOnLayerAcres
            setAddOnLayerAcres(addOnLayerAcres - (preAddOnAcreCost - overAcres));
          }
        } else {
          setAddOnLayerDollars(addOnLayerDollars + twoDecimals(+extraAcres * +projByProjDollarPerAcre * +addOrSubtract));
          setAddOnLayerAcres(addOnLayerAcres + twoDecimals(+inLicenseAcres * +addOrSubtract));
        }
      }
      if (type === 'deliverable') {
        if (addOnDeliverableDollars > 0 && !checked && inLicenseAcres > 0) {
          if (preCost < addOnDeliverableDollars) {
            setAddOnDeliverableDollars(addOnDeliverableDollars - preCost);
          } else {
            setAddOnDeliverableDollars(0);

            const overAcres = twoDecimals(+addOnDeliverableDollars / +projByProjDollarPerAcre);

            setAddOnDeliverableAcres(addOnDeliverableAcres - (preAddOnAcreCost - overAcres));
          }
        } else {
          setAddOnDeliverableDollars(addOnDeliverableDollars + twoDecimals(+extraAcres * +projByProjDollarPerAcre * +addOrSubtract));
          setAddOnDeliverableAcres(addOnDeliverableAcres + twoDecimals(+inLicenseAcres * +addOrSubtract));
        }
      }
    }
  };

  const finalSubmit = async () => {
    // Add Pendo Track Events
    const additionalDeliverables = selectedAdditionals.map((del) => del.name);
    window?.pendo?.track('Submit Order', {
      bundle: selectedBundle.name,
      addOns: additionalDeliverables,
      expeditedDelivery: deliverySpeed,
      projectName: project?.name,
      projectId: project?._id,
      kmlSize: activeOrder.acreage ? activeOrder.acreage.toFixed(2) : '0',
      credits: totalCredits ? totalCredits.toFixed(2) : '0',
      expressAI: expressAIBundle,
    });
    setIsSubmitting(true);
    setCurrentOrderAcreage(activeOrder.acreage);
    setCurrentOrderLength(activeOrder.kmlLength);
    if (activeOrder.middlePoint) {
      const middlePt = `${activeOrder.middlePoint.place}, ${activeOrder.middlePoint.region} ${activeOrder.middlePoint.postcode}`;
      setMiddlePoint(middlePt);
      const coords = `${activeOrder.middlePoint.longitude.toFixed(5)}, ${activeOrder.middlePoint.latitude.toFixed(5)}`;
      setCoordinates(coords);
    }
    setReferenceId(activeOrder.referenceId);

    const currentTeam = availableTeams?.find((t) => t._id === activeOrder.team);
    setTeam(currentTeam?.name);
    await dispatch(PlaceOrderThunk(cadTemplate, deliveryDate, bundleType, pricePerFoot));
    setIsSubmitting(false);
  };

  const resetDollarCostValues = () => {
    setTotalDollar(0);
    setPricePerFoot(0);
    setTotalTax(0);
    setOverageInfo(null);
  };

  const contactSalesClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setContactSalesInteraction(true);
    setCreditOverageAcknowledged(true);
    window.open('mailto:sales@airworks.io', '_blank');
  };

  const goToNextPage = async () => {
    if (page === 0) {
      await checkFileDependenciesAndErrors();
      return;
    }
    if (page === 2) {
      finalSubmit();
      return;
    }
    const charges = +totalCost + +addOnDeliverableDollars + +addOnLayerDollars + +imageryExtraDollarCost;
    if (page === 1 && charges > 0) {
      if (cards) {
        if (selectedBundle.bundleId === 4 && !carouselDismissed) {
          setShow3DFECarousel(true);
        }
        setPage(page + 1);
      }
    } else {
      if (selectedBundle.bundleId === 4 && !carouselDismissed) {
        setShow3DFECarousel(true);
      }
      setPage(page + 1);
    }
  };

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setFileDepenciesExist(false);
    setErrorsExist(false);
    setShowConfirmDialog(false);
    setShowErrorsDialog(false);
    e.stopPropagation();
  };

  const onClose = () => {
    setOrderFormVisible(false);
    ResetBundleStateAction();
    resetDollarCostValues();
    setPage(0);
    dispatch(SetPlaceOrderSuccessAction(false));
    if (!_ADMIN_ && placeOrderSuccess) {
      navigate('/projects');
    }
  };

  const changeEstimate = () => {
    setShowChangeWarning(false);
    setFirstTimeChangeEstimate(false);
    next();
    setNext(null);
    setIsSaved(false);
    setinLicenseAddonAcres(0);
  };

  const checkFirstTimeChangeEstimate = (fun: Function) => {
    if (firstTimeChangeEstimate) {
      setNext(() => fun);
      setShowChangeWarning(true);
    } else fun();
  };

  const goBack = () => {
    if (page === 2 && (addOnDeliverableDollars + addOnLayerDollars) === 0) {
      setPage(page - 2);
    } else {
      setPage(page - 1);
    }
  };

  return (
    <Box>
      <Box sx={styles.pageControls}>
        <Box sx={styles.pageControlsSub}>
          <Box sx={styles.backButton}>
            {(page > 0 && !placeOrderSuccess) && (
              <IconButton color="inherit" aria-label="Back" onClick={goBack}>
                <KeyboardBackspaceIcon fontSize="large" />
              </IconButton>
            )}
          </Box>
          <Box sx={styles.tabs}>
            {!placeOrderSuccess && (
              <>
                <Typography
                  variant="body1"
                  sx={{ ...(page === 0 && styles.activeTabName) }}
                >
                  1. Select Add-Ons
                </Typography>
                <ChevronRightIcon sx={styles.chevron} />
                <Typography
                  variant="body1"
                  sx={{ ...(page === 1 && styles.activeTabName) }}
                >
                  2. Payment
                </Typography>
                <ChevronRightIcon sx={styles.chevron} />
                <Typography
                  variant="body1"
                  sx={{ ...(page === 2 && styles.activeTabName) }}
                >
                  3. Confirm and Process
                </Typography>
              </>
            )}
          </Box>
          <Box sx={styles.closeButtonWrapper}>
            <IconButton color="inherit" onClick={onClose} aria-label="Close">
              <CloseIcon fontSize="large" />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.pageWrapper}>
        {page === 0 && (
          <Package
            {...{
              setOrderFormVisible,
              deliverySpeed,
              isSaved,
              showChangeWarning,
              setShowChangeWarning,
              checkFirstTimeChangeEstimate,
              page,
              bundleStep,
              setBundleStep,
              pricePerFoot, 
              setPricePerFoot,
              manageAddOns,
              addOnDeliverableDollars,
              addOnLayerDollars,
              zeroAddOns,
              allowTemplateReview,
              setAllowTemplateReview,
              setCheckedForOverage,
              bundleType,
              setBundleType,
              bundleSubtype,
              setBundleSubtype,
            }}
          />
        )}
        {page === 1 && <BillingColumn {...{ setCards, orderFormVisible, additonalCharges, setTotalTax, checkFirstTimeChangeEstimate }} />}
        {page === 2 && <ConfirmColumn {...{ setPage, additonalCharges }} />}
        <Box>
          <MapColumn
            {...{
              goToNextPage,
              cadTemplate,
              setCadTemplate,
              setShowTemplateReviewDialog,
              setDeliverySpeed,
              deliverySpeed,
              isSubmitting,
              setOrderFormVisible,
              currentOrderAcreage,
              currentOrderLength,
              middlePoint,
              referenceId,
              team,
              coordinates,
              page,
              setPage,
              isSaved,
              isSaving,
              firstTimeChangeEstimate,
              saveEstimate,
              showChangeWarning,
              setShowChangeWarning,
              checkFirstTimeChangeEstimate,
              bundleStep,
              cards,
              // This for when JS gets tired and doesn't want to do math all the way
              addOnLayerAcres: +(addOnLayerAcres.toFixed(2)),
              addOnDeliverableAcres: +(addOnDeliverableAcres.toFixed(2)),
              addOnDeliverableDollars,
              addOnLayerDollars,
              totalCredits,
              setTotalCredits,
              totalDollar,
              setTotalDollar,
              pricePerFoot,
              setPricePerFoot,
              totalTax,
              setTotalTax,
              creditsToMin,
              setCreditsToMin,
              inLicenseAddonAcres,
              setinLicenseAddonAcres,
              setPricingInfo,
              pricingInfo,
              additonalCharges,
              setAdditonalCharges,
              allowTemplateReview,
              setAllowTemplateReview,
              setCheckedForOverage,
              bundleType,
            }}
          />
        </Box>
      </Box>
      <WarningDeliverableDialog
        showDialog={fileDepenciesExist && showConfirmDialog}
        onOk={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          setShowConfirmDialog(false);
          setUploadFilesFormVisible(true);
          e.stopPropagation();
        }}
        onCancel={onCancel}
        titleText="Your order requires data to be uploaded to proceed with processing"
        questionText="Would you like to upload files now?"
        okText="Yes, upload now"
        tifDependencyList={tifDependencies}
        lasDependencyList={lasDependencies}
      />
      <ErrorDialog
        showDialog={showErrorsDialog}
        onAccept={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          setShowErrorsDialog(false);
          setUploadFilesFormVisible(true);
          e.stopPropagation();
        }}
        onCancel={onCancel}
        titleText="Your files have errors that require resolution to proceed with processing."
        error
        acceptText="Yes, upload now"
        cancelText="Upload Later"
      >
        <Box sx={styles.errorMessage}>
          {fileStatusJson && Object.keys(fileStatusJson).length > 0 && (
            Object.keys(fileStatusJson).map((file) => (
              (fileStatusJson[file].status === 'ERROR') ? (
                <div key={file}>
                  <Typography variant="body1" sx={styles.errorText}>
                    The following errors were found in
                    <Typography sx={styles.errorFile}>{` ${file}:`}</Typography>
                  </Typography>
                  {fileStatusJson[file].messages.map((message) => (
                    <Typography variant="body1" sx={styles.errorItem} key={message}>
                      {`${message}`}
                    </Typography>
                  ))}
                </div>
              ) : null))
          )}
          <Typography variant="h3">Would you like to resolve errors and re-upload files now?</Typography>
        </Box>
      </ErrorDialog>
      <ErrorDialog
        showDialog={showStillProcessingDialog}
        onCancel={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          setShowStillProcessingDialog(false);
          e.stopPropagation();
        }}
        titleText="Still validating your upload"
        cancelText="OKAY"
        error
      >
        <Typography variant="body1" sx={styles.errorMessage}>
          We’re not yet able to detect a valid
          <span style={styles.bold}> EPSG </span>
          code for your file. We’ll share a detailed error message if we have one.
        </Typography>
      </ErrorDialog>
      <ErrorDialog
        showDialog={showEpsgMismatchDialog}
        onCancel={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          setShowEpsgMismatchDialog(false);
          setEpsgMismatchAcknowledged(true);
          window?.pendo?.track('EPSG conflict', { projectId: project?._id, files: files?.map((file) => file.name).join(', ') });
          e.stopPropagation();
        }}
        onAccept={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          setShowEpsgMismatchDialog(false);
          setUploadFilesFormVisible(true);
          e.stopPropagation();
        }}
        titleText="EPSG Code Discrepancy"
        acceptText="Upload files"
        cancelText="Ignore, Proceed to Project"
        error={false}
      >
        <>
          {files.map((file) => (
            <Typography key={file._id} variant="body1" sx={styles.filesList}>{`${file.name} - EPSG: ${file.epsg}`}</Typography>
          ))}
          <Typography variant="body1" sx={styles.epsgErrorMessage}>
            We have detected conflicting EPSG codes from the files you have uploaded.
            We strongly recommend uploading your input data in the same CRS to avoid potential delays or quality issues in your final deliverable.
            {' '}
            <a style={styles.link} href="https://awkb.scrollhelp.site/airworksknowledgebase/coordinate-reference-system-crs" target="_blank" rel="noreferrer">Click here to learn more</a>
          </Typography>
        </>
      </ErrorDialog>
      <UploadFilesDialog
        showDialog={uploadFilesFormVisible}
        setShowDialog={setUploadFilesFormVisible}
        orderFormVisible={orderFormVisible}
        setOrderFormVisible={setOrderFormVisible}
      />
      <ConfirmProceedDialog
        title="Heads up! Adjusting your order will change your saved estimate."
        contentText="Are you sure you want to proceed?"
        showDialog={showChangeWarning}
        cancelButtonText="CANCEL"
        okButtonText="PROCEED"
        onOk={changeEstimate}
        onCancel={() => {
          setNext(null);
          setShowChangeWarning(false);
        }}
      />
      <CreditOverageDialog
        showDialog={showCreditOverageDialog && !contactSalesInteraction}
        onOk={contactSalesClick}
        onCancel={() => {
          setContactSalesInteraction(true);
          setCreditOverageAcknowledged(true);
        }}
        overageInfo={overageInfo}
        totalDollar={totalDollar}
        pricingPlanName={pricingPlanName}
      />
      <ConfirmProceedDialog
        title="Template confirmation"
        contentText="Your order is ready for submission! Please take a few seconds to make sure your deliverable is set to the right template before confirming your order!"
        showDialog={showTemplateReviewDialog && checkedForOverage && allowTemplateReview}
        cancelButtonText="DISMISS"
        okButtonText="CONFIRM TEMPLATE"
        onOk={reviewCadTemplate}
        onCancel={() => setShowTemplateReviewDialog(false)}
      />
      <Carousel3DFE show3DFECarousel={show3DFECarousel} setShow3DFECarousel={setShow3DFECarousel} setCarouselDismissed={setCarouselDismissed} finalSubmit={finalSubmit} isSubmitting={isSubmitting} setPage={setPage} page={page} />
    </Box>
  );
};

export default OrderForm;
