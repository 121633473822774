// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const useStyles = (theme: any) => ({
  kmlToolbar: {
    backgroundColor: '#fff',
    padding: '8px 16px',
  },
  expressButtonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-8px',
    marginLeft: '-8px',
  },
  bufferText: {
    display: 'flex',
    flexDirection: 'row',
    boxShadow: '0px 2px 12px 0px rgba(2,30,64,0.1)',
  },
  unitDiv: {
    backgroundColor: '#FAFAFC',
    width: '35px',
    border: '1px solid #EAEAEA',
    padding: theme.spacing(1),
    height: '35px',
    color: '#788898',
  },
  textField: {
    fontWeight: 300,
    letterSpacing: '1.3px',
    maxWidth: '100px',
    fontSize: '14px',
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1),
    },
    '& fieldset': {
      borderRadius: 0,
      borderColor: '#EAEAEA',
    },
  },
  bufferForm: {
    marginTop: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
});

export default useStyles;
