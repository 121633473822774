import { createAction } from '@reduxjs/toolkit';

export const UploadingDrawnKmlAction = createAction<boolean>('mapDraw/UploadingDrawnKmlAction');

export const SetCreateKmlAction = createAction<boolean>('mapDraw/SetCreateKmlAction');
export const SetAddToExistingKmlAction = createAction<boolean>('mapDraw/SetAddToExistingKmlAction');

export const ResetKmlDrawAction = createAction('mapDraw/ResetKmlDrawAction');

export const ClearDrawnKmlAction = createAction('mapDraw/ClearDrawnKmlAction');

export const SetKmlLineDrawAction = createAction<boolean>('mapDraw/SetKmlLineDrawAction');

export const SetModeAction = createAction<{ name: string, params?: { featureId: string } }>('mapDraw/SetModeAction');

export const UpdateKmlFeatureAction = createAction<GeoJSON.Feature>('mapDraw/UpdateKmlFeatureAction');
export const SetKmlFeatureAction = createAction<GeoJSON.FeatureCollection>('mapDraw/SetKmlFeatureAction');

export const SetCurrentMeasurementOptionAction = createAction<string>('mapDraw/SetCurrentMeasurementOptionAction');
export const SetMeasurementModeAction = createAction('mapDraw/SetMeasurementModeAction');

export const SetFirstTimeChangeEstimateAction = createAction<boolean>('mapDraw/SetFirstTimeChangeEstimateAction');

export const SetSelectionFeaturesAction = createAction<GeoJSON.Feature[]>('mapDraw/SetSelectionFeaturesAction');
export const SetSelectionPointsAction = createAction<GeoJSON.FeatureCollection<GeoJSON.Point>[]>('mapDraw/SetSelectionPointsAction');
export const ResetSelectionAction = createAction('mapDraw/ResetSelectionAction');

export const DeleteKmlFeatureAction = createAction<GeoJSON.Feature>('mapDraw/DeleteKmlFeatureAction');
