// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Collapse, IconButton, Typography, Tabs, Tab, ListItemButton, Button } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import GestureIcon from '@mui/icons-material/Gesture';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import HelpIcon from '@mui/icons-material/Help';
import SVG from 'react-inlinesvg';
import downloadIcon from 'Assets/download-icon.png';
import uploadKmlIcon from 'Assets/upload-kml-icon.svg';
import roadNetworkIcon from 'Assets/road-network-icon.png';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { WideLightTooltip } from 'Components/Tooltip';
import ConfirmDialog from 'Components/ConfirmDialog';
import ConfirmProceedDialog from 'Components/ConfirmProceedDialog';
import { getOrders } from 'Features/order/orderSelectors';
import { getRasterRequestStatus } from 'Features/project/projectSelectors';
import { SetCreateKmlAction} from 'Features/map/mapDrawActions';
import { Clear, StartKmlDraw, CancelDraw } from 'Features/map/mapDrawThunk';
import { SetShowKmlToolbarAction } from 'Features/project/projectActions';
import { ResetBufferStateAction } from 'Features/kml/kmlActions';
import { DownloadKMLThunk,SetRoadNetworkThunk } from 'Features/kml/kmlThunk';
import { ShowUploadSingleFileDialogAction } from 'Features/fileUpload/fileUploadActions';
import stylesDef from './styles';

const KmlPanel = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const orders = useAppSelector((state) => getOrders(state));

  const { nonActiveOrders, activeOrder } = orders;
  let activeOrderKml: { id: any; name: any; isEstimate: any; deliveryDays: any; geomType?: string };
  if (activeOrder.boundaryFile) {
    activeOrderKml = {
      id: activeOrder._id,
      name: activeOrder.boundaryFile.split('/').pop(),
      isEstimate: activeOrder.isEstimate,
      deliveryDays: activeOrder.deliveryDays,
      geomType: activeOrder.geomType,
    };
  }

  const activeKmlUrl = activeOrder.boundaryFile;
  const { isEstimate } = activeOrder;
  const kmls = useAppSelector((state) => state.kml.present.kmls);
  const mode = useAppSelector((state) => state.map.draw.present.mode);
  const showBusinessDays = useAppSelector((state) => state.kml.present.showBusinessDays);

  const nonActiveOrdersKmls = nonActiveOrders
    .filter((o) => o.boundaryFile)
    .map((o) => {
      const kml = kmls[o._id];
      return {
        id: o._id,
        name: o.boundaryFile.split('/').pop(),
      };
    });

  const currentProject = useAppSelector((state) => state.project.project);
  const imageryRequested = (activeOrder?.isEstimate && !!(currentProject?.imageryRequest?.requested)) || !!(currentProject?.imageryRequest?.uploaded) || !!(currentProject?.imageryRequest?.notificationSent);
  const rasterRequest = useAppSelector((state) => getRasterRequestStatus(state));
  const rasterizationDisable = rasterRequest.requested || rasterRequest.rejected;
  const [kmlExpanded, setKmlExpanded] = useState(true);
  const toggleKmlExpanded = () => {
    setKmlExpanded(!kmlExpanded);
  };

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [onDialogOk, setOnDialogOk] = useState(null);
  const [showConfirmProceedDialog, setShowConfirmProceedDialog] = useState(false);

  const hasExistingKml = kmls[orders.activeOrder._id]?.featureCollection.features.length > 0;
  const drawKmlClick = () => {
    if (hasExistingKml) {
      if (isEstimate) {
        setShowConfirmProceedDialog(true);
      } else {
        setShowConfirmDialog(true);
      }
      setOnDialogOk('startDraw');
    } else {
      dispatch(SetCreateKmlAction(true));
      dispatch(SetShowKmlToolbarAction(true));
      dispatch(StartKmlDraw());
      dispatch(ResetBufferStateAction());
    }
  };

  const uploadKmlClick = (e: React.ChangeEvent<{}>) => {
    if (hasExistingKml) {
      setShowConfirmDialog(true);
      setOnDialogOk('showUploadKml');
    } else {
      dispatch(CancelDraw());
      dispatch(SetShowKmlToolbarAction(false));
      dispatch(ShowUploadSingleFileDialogAction());
      dispatch(ResetBufferStateAction());
    }
    e.stopPropagation();
  };

  const onOk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    setShowConfirmProceedDialog(false);
    dispatch(Clear());
    if (onDialogOk === 'startDraw') {
      dispatch(SetCreateKmlAction(true));
      dispatch(SetShowKmlToolbarAction(true));
      dispatch(StartKmlDraw());
    } else if (onDialogOk === 'showUploadKml') {
      dispatch(CancelDraw());
      dispatch(SetShowKmlToolbarAction(false));
      dispatch(ShowUploadSingleFileDialogAction());
    }
    dispatch(ResetBufferStateAction());
    e.stopPropagation();
  };

  const onCancel = () => {
    setShowConfirmDialog(false);
  };

  const KmlDownloadClick = async () => {
    await dispatch(DownloadKMLThunk(activeOrderKml.id, activeOrderKml.name));
  };

  let tabValue: boolean | 'draw' | 'upload' = false;
  if (mode && mode.name === 'draw_polygon') {
    tabValue = 'draw';
  }
  if (showConfirmDialog) {
    tabValue = 'upload';
  }

  return (
    <>
      <ListItemButton sx={styles.expansionPanelHeader}>
        <Typography variant="h5">PROCESSING AREA</Typography>
        {activeKmlUrl && (
          <IconButton
            aria-label="Download .kml"
            sx={styles.button}
            onClick={KmlDownloadClick}
          >
            <img src={downloadIcon} alt="Download .kml" />
          </IconButton>
        )}
        <IconButton aria-label={kmlExpanded ? 'Collapse' : 'Expand'} sx={styles.button} onClick={toggleKmlExpanded}>
          {kmlExpanded
            ? <KeyboardArrowDownIcon fontSize="small" />
            : <KeyboardArrowRightIcon fontSize="small" />}
        </IconButton>
      </ListItemButton>
      <Collapse in={kmlExpanded}>
        <Box sx={styles.kmlFiles}>
          {nonActiveOrdersKmls.map((kml) => (
            <Typography variant="body2" sx={styles.kmlHideButton} key={kml.name}>{kml.name}</Typography>
          ))}
          {activeOrderKml ?
            (
              <>
                <Typography variant="body2" sx={styles.kmlHideButton}>{activeOrderKml.name}</Typography>
                {activeOrderKml.isEstimate && showBusinessDays ? (
                  <>
                    {
                      activeOrderKml.deliveryDays > 20 ? (
                        <Box sx={styles.businessDays}>
                          <Typography variant="body2" sx={styles.businessDaysText}>
                            This project is eligible for super expedited delivery
                          </Typography>
                          <WideLightTooltip
                            placement="bottom"
                            title={(
                              <Box>
                                Need this back super fast? Our team can provide a custom delivery date for this project. Reach out to
                                {' '}
                                <a href="mailto:sales@airworks.io">sales@airworks.io</a>
                                {' '}
                                to get a quote.
                              </Box>
                            )}
                          >
                            <HelpIcon sx={styles.helpIcon} />
                          </WideLightTooltip>
                        </Box>
                      ) : (
                        <Box sx={styles.businessDays}>
                          <Typography variant="body2" sx={styles.businessDaysText}>
                            {activeOrderKml.deliveryDays}
                            {' '}
                            Businesss Days
                          </Typography>
                          <WideLightTooltip
                            placement="bottom"
                            title={(
                              <Box>
                                The actual delivery date of your project is subject to change at the time you finalize your order.
                              </Box>
                            )}
                          >
                            <HelpIcon sx={styles.helpIcon} />
                          </WideLightTooltip>
                        </Box>
                      )
                    }
                  </>
                ) : null}
              </>
            )
            : null}
        </Box>
        <Box sx={styles.kmlTabsWrapper}>
          <Tabs
            variant="fullWidth"
            indicatorColor="primary"
            value={tabValue}
            onChange={(event, v) => {
              if (v === 'draw') drawKmlClick();
              if (v === 'upload') uploadKmlClick(event);
            }}
          >
            <Tab
              icon={<GestureIcon />}
              label="Draw"
              value="draw"
              disabled={imageryRequested || rasterizationDisable}
              sx={styles.kmlTab}
            />
            <Tab
              icon={<SVG src={uploadKmlIcon} css={styles.tabIcon} />}
              value="upload"
              label="Upload"
              disabled={imageryRequested || rasterizationDisable}
              sx={styles.kmlTab}
            />
          </Tabs>
          {activeKmlUrl && activeOrderKml.geomType === 'Polygon' && !activeKmlUrl.includes('road_network_buffered.kml') && (
            <Button
              aria-label="Get Road Network"
              sx={styles.button}
              onClick={() => dispatch(SetRoadNetworkThunk(true))}
            >
              <img src={roadNetworkIcon} alt="Get Road Network" />
              <span>&nbsp;Get Road Network</span>
            </Button>
          )}
        </Box>
      </Collapse>
      <ConfirmDialog
        showDialog={showConfirmDialog}
        onOk={onOk}
        onCancel={onCancel}
        contentText="Current .kml file will be removed"
      />
      <ConfirmProceedDialog
        title="Deleting the KML will modify your saved estimate."
        contentText="Are you sure you want to proceed?"
        showDialog={showConfirmProceedDialog}
        cancelButtonText="CANCEL"
        okButtonText="PROCEED"
        onOk={onOk}
        onCancel={() => setShowConfirmProceedDialog(false)}
      />
    </>
  );
};

export default KmlPanel;
