// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  childrenArray: {
    display: 'flex',
    flexDirection: 'column',
    ml: 1,
    pl: 2,
    borderLeft: '3px solid #B3BBC6',
  },
});

export default styles;
