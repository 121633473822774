// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Button, Typography, TextField, Fade, CircularProgress } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { defaultBufferSize, defaultRoadNetworkBufferSize, minimumRoadNetworkBufferSize, mininumBufferSize } from 'Utils/constants';
import { GenerateKmlBufferThunk } from 'Features/kml/kmlThunk';
import { useSelector } from 'react-redux';
import { RootState } from 'Store';
import { getOrders } from 'Features/order/orderSelectors';
import { SetBufferSizeAction } from 'Features/kml/kmlActions';
import stylesDef from './styles';

export const BufferToolBar = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const bufferSize = useAppSelector((state) => state.kml.present.bufferSize);
  const creatingBuffer = useAppSelector((state) => state.kml.present.creatingBuffer);
  const { activeOrder } = useSelector((state: RootState) => getOrders(state));
  const { geomType } = activeOrder;
  const creatingRoadNetworkBuffer = useAppSelector((state) => state.kml.present.creatingRoadNetworkBuffer);
  const polygonKml = geomType === 'Polygon';
  const [buffer, setBuffer] = useState(bufferSize);

  const defaultBuffer = polygonKml ? defaultRoadNetworkBufferSize : defaultBufferSize;
  const minBuffer = polygonKml ? minimumRoadNetworkBufferSize : mininumBufferSize;

  useEffect(() => {
    setBuffer(bufferSize);
  }, [bufferSize]);

  const onBufferSubmit = () => {
    let finalBufferSize = defaultBuffer;
    if (!Number.isNaN(buffer)) {
      finalBufferSize = Math.max(buffer, minBuffer);
    }

    setBuffer(finalBufferSize);
    dispatch(SetBufferSizeAction(finalBufferSize));
    dispatch(GenerateKmlBufferThunk(polygonKml));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBuffer(parseFloat(e.target.value));
  };

  const handleBlur = () => {
    if (buffer < minBuffer) {
      setBuffer(minBuffer);
    }
  };

  return (
    <Box sx={styles.kmlToolbar}>
      <Typography variant="h4">Buffer Distance</Typography>
      <Box sx={styles.bufferForm}>
        <Box sx={styles.bufferText}>
          <TextField
            id="buffer"
            variant="outlined"
            type="number"
            onChange={handleChange}
            inputProps={{ min: minBuffer }}
            value={buffer.toString()}
            sx={styles.textField}
            disabled={creatingBuffer || creatingRoadNetworkBuffer}
            onBlur={handleBlur}
          />
          <Box sx={styles.unitDiv}>
            <Typography variant="h5">ft</Typography>
          </Box>
        </Box>
        <Button
          variant="text"
          color="primary"
          onClick={onBufferSubmit}
        >
          <Fade in={creatingBuffer || creatingRoadNetworkBuffer}>
            <CircularProgress size={16} sx={styles.expressButtonProgress} />
          </Fade>
          <Fade in={!creatingBuffer || !creatingRoadNetworkBuffer}>
            <span>Apply</span>
          </Fade>
        </Button>
      </Box>
    </Box>
  );
};

export default BufferToolBar;
