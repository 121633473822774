// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Button, Fade, CircularProgress } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import SVG from 'react-inlinesvg';
import area from 'Assets/area-icon.svg';
import polyline from 'Assets/toolbar-polyLine.svg';
import areaActive from 'Assets/area-icon-active.svg';
import polylineActive from 'Assets/toolbar-polyLine-active.svg';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { SaveDrawnKmlLine, StartKmlDraw, SaveDrawnKmlPolygon, SetMode, CancelEdit } from 'Features/map/mapDrawThunk';
import { useSelector } from 'react-redux';
import { RootState } from 'Store';
import { getOrders } from 'Features/order/orderSelectors';
import stylesDef from './styles';
import { SetShowKmlToolbarAction } from '../projectActions';

export const KmlToolBar = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const currentKmlCount = useAppSelector((state) => state.map.draw.present.currentKml?.features?.length);
  const creatingBuffer = useAppSelector((state) => state.kml.present.creatingBuffer);
  const kmlLine = useAppSelector((state) => state.map.draw.present.kmlLine);
  const { activeOrder } = useSelector((state: RootState) => getOrders(state));
  const { geomType } = activeOrder;
  const uploadingDrawnKml = useAppSelector((state) => state.map.draw.present.uploadingDrawnKml);

  const polygonKml = geomType === 'Polygon';
  const lineKml = geomType === 'LineString';
  const saveLine = lineKml || kmlLine;

  const [activateMode, setActivateMode] = useState(geomType || 'Polygon');

  const saveLineKmlFeature = async () => {
    await dispatch(SaveDrawnKmlLine());
  };

  const savePolygonKmlFeature = async () => {
    await dispatch(SaveDrawnKmlPolygon());
  };

  const onCancel = () => {
    dispatch(SetShowKmlToolbarAction(false));
    dispatch(CancelEdit());
    dispatch(SetMode('simple_select'));
  };

  return (
    <Box sx={styles.kmlToolbar}>
      <Box sx={styles.buttonsContainer}>
        <Box>
          <Button
            sx={styles.option}
            onClick={() => {
              dispatch(StartKmlDraw('draw_polygon'));
              setActivateMode('Polygon');
            }}
            disabled={lineKml}
          >
            {activateMode === 'Polygon' ? (
              <SVG src={areaActive} title="polygon" css={styles.icon} />
            ) : (
              <SVG src={area} title="polygon" css={styles.icon} />
            )}
          </Button>
          <Button
            sx={styles.option}
            onClick={() => {
              dispatch(StartKmlDraw('draw_line_string'));
              setActivateMode('LineString');
            }}
            disabled={polygonKml}
          >
            {activateMode === 'LineString' ? (
              <SVG src={polylineActive} title="line" css={styles.icon} />
            ) : (
              <SVG src={polyline} title="line" css={styles.icon} />
            )}
          </Button>
        </Box>
        <Box>
          <Button
            color="primary"
            onClick={onCancel}
            sx={styles.cancelButton}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={saveLine ? saveLineKmlFeature : savePolygonKmlFeature}
            disabled={!currentKmlCount}
          >
            <Fade in={creatingBuffer || uploadingDrawnKml}>
              <CircularProgress size={16} sx={styles.uploadKmlProgress} />
            </Fade>
            <Fade in={!creatingBuffer && !uploadingDrawnKml}>
              <span>Save Drawing</span>
            </Fade>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default KmlToolBar;
