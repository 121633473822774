// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Box, Button, Checkbox, CircularProgress, Dialog, Fade, FormControlLabel, ListItem, Typography, useTheme } from "@mui/material";
import { EditOrderThunk } from "Features/order/orderThunk";
import { useAppDispatch } from "Hooks";
import { useEffect, useState } from "react";
import { deliverableCost } from "Utils/deliverables";
import stylesDef from './styles';
import LoadingSpinner from "Components/LoadingSpinner";

interface EditAddonsDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  orderId: string;
  projectId: string;
  orderAcreage: number;
  orderStatus: number;
  setShowOutOfCreditsDialog?: (show: boolean) => void;
  bundleDeliverables?: AddOns[];
  loadingDeliverables?: boolean;
}

export const EditAddonsDialog = ({ open, setOpen, orderId, projectId, orderAcreage, setShowOutOfCreditsDialog, orderStatus, bundleDeliverables, loadingDeliverables }: EditAddonsDialogProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [deliverableTypes, setDeliverableTypes] = useState([]);
  const [checkedDeliverables, setCheckedDeliverables] = useState([]);

  const handleCheckboxChange = (id: string) => {
    if (checkedDeliverables.includes(id)) {
      setCheckedDeliverables(checkedDeliverables.filter(item => item !== id));
    } else {
      setCheckedDeliverables([...checkedDeliverables, id]);
    }
  }

  const handleSaveChanges = async () => {
    const selectedDeliverables = checkedDeliverables.map(id => ({ id }));
    setIsLoading(true);
  
    const result = await dispatch(EditOrderThunk({ orderId, addOns: selectedDeliverables }));
    setIsLoading(false);
  
    if (result.success) {
      setOpen(false);
    } else if (result.message === 'Not enough credits available') {
      setOpen(false);
      setShowOutOfCreditsDialog(true);
    }
  };

  useEffect(() => {
    if (bundleDeliverables.length > 0) {
      const subtypesArray = [...new Set(bundleDeliverables.map((item) => item.subtype))];
      const selectedDeliverables = bundleDeliverables
        .filter((item) => item.selected === true)
        .map((item) => item._id);

      setDeliverableTypes(subtypesArray);
      setCheckedDeliverables(selectedDeliverables);
    }
  }, [bundleDeliverables]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition={false}
    >
      <Box sx={styles.dialogContainer}>
        <Typography variant="h3" sx={styles.dialogTitle}>Edit Add-ons and Deliverables</Typography>
        <Typography variant="subtitle1">Please select any additional layers you’d like to be drafted in your project.</Typography>
        <LoadingSpinner loading={loadingDeliverables}>
          <Box sx={styles.deliverablesList}>
            {
              deliverableTypes.map((type, index) => {
                return (
                  <div key={index}>
                    <Typography variant="h4">
                      {type}
                    </Typography>
                      {bundleDeliverables.map((deliverable: any) => {
                        if (deliverable.subtype === type) {
                          return (
                            <ListItem key={deliverable._id}>
                              <FormControlLabel
                                label={(
                                  <div>
                                    <Typography variant="body1" display="inline">{deliverable.name}</Typography>
                                  </div>
                                )}
                                control={(
                                  <Checkbox
                                    disabled={!deliverable.selectable}
                                    checked={checkedDeliverables.includes(deliverable._id)}
                                    onChange={() => handleCheckboxChange(deliverable._id)}
                                  />
                                )}
                              />
                              <Typography component="span">
                                {`  ${deliverableCost(deliverable.typeFactor[0], orderAcreage).toFixed(2)} credits`}
                              </Typography>
                            </ListItem>
                          )
                        }
                      })}
                  </div>
                )
              })
            }
          </Box>
        </LoadingSpinner>
        <Box sx={styles.buttonContainer}>
          <Button variant="text" sx={styles.closeButton} onClick={() => setOpen(false)}>Close</Button>
          <Button
            variant="contained"
            onClick={handleSaveChanges}
            disabled={isLoading}
          >
            <Fade in={isLoading}>
              <CircularProgress size={24} sx={styles.buttonProgress}/>
            </Fade>
            <Fade in={!isLoading}>
              <span>Save Changes</span>
            </Fade>
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
};