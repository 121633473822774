// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  svgContainer: {
    position: 'sticky',
    top: 100,
    zIndex: 1000,
    marginTop: '8vh',
  },
  componentHover: {
    cursor: 'pointer',
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverText: {
    p: 1,
  },
  visualOrderTitle: {
    fontSize: '22px',
    fontWeight: 400,
    marginBottom: '5vh',
  },
});

export default styles;
