// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Paper, Divider, Button, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import DrawIcon from '@mui/icons-material/Draw';
import { useAppDispatch, useAppSelector } from 'Hooks';
import ConfirmProceedDialog from 'Components/ConfirmProceedDialog';
import { getOrders } from 'Features/order/orderSelectors';
import { getRasterRequestStatus } from 'Features/project/projectSelectors';
import { ResetBufferStateAction, SetShowBufferInfoAction } from 'Features/kml/kmlActions';
import { getExistingKml } from 'Features/kml/kmlSelectors';
import { SetShowKmlToolbarAction } from 'Features/project/projectActions';
import { SetAddToExistingKmlAction, SetKmlFeatureAction } from '../mapDrawActions';
import { ZoomInAction, ZoomOutAction } from '../mapCommonActions';
import { Delete, StartKmlDraw } from '../mapDrawThunk';
import stylesDef from './styles';

interface ComponentProps {
  showLayers: boolean;
}

const MapControls: React.FC<ComponentProps> = ({ showLayers }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const { activeOrder } = useAppSelector((state) => getOrders(state));
  const createKml = useAppSelector((state) => state.map.draw.present.createKml);
  const currentKml = useAppSelector((state) => state.map.draw.present.currentKml);

  const currentProject = useAppSelector((state) => state.project.project);
  const imageryRequested = (activeOrder?.isEstimate && !!(currentProject?.imageryRequest?.requested)) || !!(currentProject?.imageryRequest?.uploaded) || !!(currentProject?.imageryRequest?.notificationSent);

  const kmls = useAppSelector((state) => state.kml.present.kmls);

  let uploadedKml;
  let atLeastOneKmlFeature;
  if (!_ADMIN_ && !showLayers) {
    uploadedKml = kmls[activeOrder?._id]?.featureCollection;
    atLeastOneKmlFeature = createKml ? currentKml.features.length > 0 : uploadedKml?.features.length > 0;
  }
  const rasterRequest = useAppSelector((state) => getRasterRequestStatus(state));

  const mapDrawPast = useAppSelector((state) => state.map.draw.past);
  const kmlPast = useAppSelector((state) => state.kml.past);
  const existingKml = useAppSelector((state) => getExistingKml(state));

  const hasPolygon = _ADMIN_ ? false : atLeastOneKmlFeature;
  const isEstimate = activeOrder?.isEstimate;
  const rasterizationDisable = rasterRequest.requested || rasterRequest.rejected;

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleDelete = () => {
    if (isEstimate) setShowConfirmDialog(true);
    else {
      dispatch(Delete());
    }
  };

  const handleEdit = () => {
    dispatch(SetShowBufferInfoAction(false));
    dispatch(SetShowKmlToolbarAction(true));
    dispatch(SetAddToExistingKmlAction(true));
    dispatch(SetKmlFeatureAction(existingKml));
    const drawMode = activeOrder?.geomType === 'Polygon' ? 'draw_polygon' : 'draw_line_string';
    dispatch(StartKmlDraw(drawMode));
  };

  return (
    <Box sx={styles.mapControls}>
      {hasPolygon && !showLayers && (
        <Paper elevation={1} square sx={styles.buttonPanel}>
          {/* Undo is not working -- 08/30 Piaotian */}
          {/* <Button
            onClick={handleUndo}
            disabled={!canUndo || imageryRequested || rasterizationDisable}
            sx={styles.mapButton}
          >
            <RefreshIcon />
            <Typography variant="caption">
              Undo
            </Typography>
          </Button>
          <Divider sx={styles.divider} /> */}
          {!!existingKml?.features.length && (
            <>
              <Button
                onClick={handleEdit}
                disabled={imageryRequested || rasterizationDisable}
                sx={styles.mapButton}
              >
                <DrawIcon />
                <Typography variant="caption">
                  Edit
                </Typography>
              </Button>
              <Divider sx={styles.divider} />
            </>
          )}
          <Button
            onClick={handleDelete}
            disabled={imageryRequested || rasterizationDisable}
            sx={styles.mapButton}
          >
            <CloseIcon />
            <Typography variant="caption">
              Delete
            </Typography>
          </Button>
        </Paper>
      )}
      <Paper elevation={1} square>
        <Button sx={styles.mapButton} onClick={() => dispatch(ZoomInAction())}>
          <AddIcon />
        </Button>
        <Divider sx={styles.divider} />
        <Button sx={styles.mapButton} onClick={() => dispatch(ZoomOutAction())}>
          <RemoveIcon />
        </Button>
      </Paper>

      <ConfirmProceedDialog
        title="Deleting the KML will modify your saved estimate."
        contentText="Are you sure you want to proceed?"
        showDialog={showConfirmDialog}
        cancelButtonText="CANCEL"
        okButtonText="PROCEED"
        onOk={() => {
          dispatch(Delete());
          dispatch(ResetBufferStateAction());
          setShowConfirmDialog(false);
        }}
        onCancel={() => setShowConfirmDialog(false)}
      />
    </Box>
  );
};

export default MapControls;
