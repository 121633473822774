// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { ActionCreators } from 'redux-undo';
import ToKml from 'tokml';
import GenerateGuid from 'Utils/guid';
import calculateAcres from 'Utils/map';
import type { RootState } from 'Store';
import { FileUploadStartThunk, DeleteFileThunk } from '../fileUpload/fileUploadThunk';
import { getOrders } from '../order/orderSelectors';
import { SetKmlThunk } from '../kml/kmlThunk';
import { OverwriteKmlsAction, ResetBufferStateAction } from '../kml/kmlActions';
import { SetShowKmlToolbarAction } from '../project/projectActions';
import {
  ResetKmlDrawAction,
  ClearDrawnKmlAction,
  SetKmlLineDrawAction,
  SetModeAction,
  UpdateKmlFeatureAction,
  SetFirstTimeChangeEstimateAction,
  SetSelectionFeaturesAction,
  SetSelectionPointsAction,
  DeleteKmlFeatureAction,
  UploadingDrawnKmlAction,
  ResetSelectionAction,
  SetAddToExistingKmlAction,
  SetCreateKmlAction,
  SetKmlFeatureAction,
} from './mapDrawActions';

const RemoveKml = () =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    await DeleteFileThunk('kml')(dispatch, getState);
  };

export const Clear = (startDraw: boolean = false) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    if (!startDraw) dispatch(ResetKmlDrawAction());
    RemoveKml()(dispatch, getState);
    dispatch(ActionCreators.clearHistory());
  };

export const StartDraw = (mode: string = 'draw_polygon') => (dispatch: Dispatch) => SetMode(mode)(dispatch);

export const SetMode = (name: string, params?: { featureId: string }) => (dispatch: Dispatch) => dispatch(SetModeAction({ name, params }));

export const StartKmlDraw =
  (mode: string = 'draw_polygon') =>
    (dispatch: Dispatch, getState: () => RootState) => {
      const { kmlLine } = getState().map.draw.present;
      const { createKml } = getState().map.draw.present;
      if (createKml && mode === 'draw_line_string' && !kmlLine) {
        Clear(true)(dispatch, getState);
        dispatch(ClearDrawnKmlAction());
        dispatch(SetKmlLineDrawAction(true));
      } else if (createKml && mode === 'draw_polygon' && kmlLine) {
        Clear(true)(dispatch, getState);
        dispatch(ClearDrawnKmlAction());
        dispatch(SetKmlLineDrawAction(false));
      }
      SetMode(mode)(dispatch);
    };

export const CancelDraw = () =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const orders = getOrders(getState());
    const order = orders.activeOrder;

    const featureCollection = getState().kml.present.kmls[order._id] && getState().kml.present.kmls[order._id].featureCollection;
    if (!featureCollection || featureCollection.features.length === 0) {
      SetMode('simple_select')(dispatch);
    }
    dispatch(ResetKmlDrawAction());
  };

export const CancelEdit = () =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const { activeOrder } = getOrders(getState());
    if (activeOrder?._id) {
      const featureCollection = getState().kml.present.kmls[activeOrder._id]?.featureCollection;
      dispatch(SetKmlFeatureAction(featureCollection));
    }
  };

export const InitDraw = () =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    // eslint-disable-next-line no-use-before-define
    const orders = getOrders(getState());
    const { activeOrder } = orders;
    const featureCollection = getState().kml.present.kmls[activeOrder._id] && getState().kml.present.kmls[activeOrder._id].featureCollection;
    if (!featureCollection || featureCollection.features.length === 0) return;
    SetMode('direct_select', { featureId: featureCollection.features[0].id as string })(dispatch);
    dispatch(ActionCreators.clearHistory());
  };

const UploadKml = (order: IOrder, featureCollection: GeoJSON.FeatureCollection, addToExistingKml: boolean = false) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const fileName = (order.boundaryFile && order.boundaryFile.split('/').pop()) || `${order._id}.kml`;
    const kml = ToKml(featureCollection);
    const blob = new Blob([kml], { type: 'application/vnd.google-earth.kml+xml' });

    const fileBlob = new File([blob], fileName);
    await FileUploadStartThunk([fileBlob], true, false, addToExistingKml)(dispatch, getState);
  };

const Save = (collection?: GeoJSON.FeatureCollection, kmlType?: string, skipUpdateKml: boolean = false, addToExistingKml: boolean = false) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const orders = getOrders(getState());
    const order = orders.activeOrder;

    const featureCollection = collection || getState().kml.present.kmls[order._id].featureCollection;

    if (featureCollection.features.length === 0) {
      const deletedAcres = calculateAcres(getState().kml.present.kmls[order._id].featureCollection);
      window?.pendo?.track('Delete KML', { deletedAcres, projectId: order.ownerProject });
      await DeleteFileThunk('kml')(dispatch, getState);
      return;
    }

    if (!skipUpdateKml) SetKmlThunk(featureCollection)(dispatch, getState);
    const acres = calculateAcres(featureCollection);
    if (collection) {
      window?.pendo?.track('Draw KML', { projectId: order.ownerProject, area: acres, ...(kmlType && { KML_type: kmlType }) });
    } else {
      window?.pendo?.track('Update KML', { acres, projectId: order.ownerProject });
    }
    await UploadKml(order, featureCollection, addToExistingKml)(dispatch, getState);
    dispatch(SetAddToExistingKmlAction(false));
    dispatch(SetCreateKmlAction(false));
  };

export const Undo = () =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const { createKml } = getState().map.draw.present;
    const drawingHistory = getState().map.draw.past;
    if (createKml) {
      if (drawingHistory.length === 1) {
        dispatch(ClearDrawnKmlAction());
        dispatch(ActionCreators.clearHistory());
      } else {
        dispatch(ActionCreators.undo());
        SetMode('simple_select')(dispatch);
      }
    } else {
      dispatch(ActionCreators.undo());
      Save(null, null, true)(dispatch, getState);
    }
  };

export const Draw = (feature?: GeoJSON.Feature<GeoJSON.Polygon>) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { createKml, addToExistingKml, currentKml } = getState().map.draw.present;
    // Check if we are in the kml creation stage and we are trying to add or update features in the drawing
    if ((createKml || addToExistingKml) && feature) {
      dispatch(UpdateKmlFeatureAction(feature));
      return { isEstimate: false, firstTimeChangeEstimate: false };
    }

    // Not creating a kml, but editing an existing polygon kml
    if (feature && !createKml) {
      await EditKml(feature)(dispatch, getState);
    }

    // When draw kml, check if the order is estimate, and if this is the first time to change estimate. Only show alert dialog when both is true.
    const { firstTimeChangeEstimate } = getState().map.draw.present;
    const { isEstimate } = getOrders(getState()).activeOrder;
    if (isEstimate && firstTimeChangeEstimate) {
      dispatch(SetFirstTimeChangeEstimateAction(false));
    }

    return {
      isEstimate,
      firstTimeChangeEstimate,
    };
  };

const EditKml = (feature?: GeoJSON.Feature) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const orders = getOrders(getState());
    const order = orders.activeOrder;

    const currentFeatures = [...getState().kml.present.kmls[order._id].featureCollection.features];

    const featureIndex = currentFeatures.findIndex((f) => f.id === feature.id);
    currentFeatures[featureIndex] = feature;
    const featureCollection: GeoJSON.FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };
    featureCollection.features = currentFeatures;

    const acres = calculateAcres(featureCollection);
    window?.pendo?.track('Update KML', { acres: acres?.toFixed(2), projectId: order.ownerProject });
    SetKmlThunk(featureCollection)(dispatch, getState);
    await UploadKml(order, featureCollection)(dispatch, getState);
  };

export const SetSelection = (selectedFeatures: { features: GeoJSON.Feature[], points: GeoJSON.FeatureCollection<GeoJSON.Point>[] }) =>
  (dispatch: Dispatch) => {
    dispatch(SetSelectionFeaturesAction(selectedFeatures?.features));
    dispatch(SetSelectionPointsAction(selectedFeatures?.points));
    if (selectedFeatures.features.length === 1) {
      SetMode('direct_select', { featureId: selectedFeatures.features[0].id as string })(dispatch);
    } else {
      SetMode(null)(dispatch);
    }
  };

export const SelectPolygon = () =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const { createKml, currentKml, addToExistingKml } = getState().map.draw.present;
    if (createKml || addToExistingKml) {
      if (currentKml.features.length === 0) return;
      SetMode('simple_select')(dispatch);
    }
  };

export const Delete = () =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { createKml, addToExistingKml } = getState().map.draw.present;
    if (createKml || addToExistingKml) {
      const { selectedFeatures } = getState().map.draw.present;
      const modeName = getState().map.draw.present.mode?.name;
      if (selectedFeatures?.features.length === 1 && modeName === 'direct_select') {
        const feature = selectedFeatures.features[0];
        const polygonCoordinates = [...feature.geometry.coordinates[0]];
        if (selectedFeatures.points.length === 0 || polygonCoordinates.length === 4 || feature.geometry.type === 'LineString') {
          dispatch(DeleteKmlFeatureAction(feature));
          dispatch(ResetSelectionAction());
        } else {
          const selectedArr = selectedFeatures.points[0].geometry.coordinates;
          const index = polygonCoordinates.findIndex((arr) => arr[0] === selectedArr[0] && arr[1] === selectedArr[1]);

          if (index === 0 || index === polygonCoordinates.length - 1) {
            polygonCoordinates.shift();
            [polygonCoordinates[polygonCoordinates.length - 1]] = polygonCoordinates;
          } else {
            polygonCoordinates.splice(index, 1);
          }

          const newFeature: GeoJSON.Feature<GeoJSON.Polygon> = {
            id: GenerateGuid(),
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [[...polygonCoordinates]],
            },
            properties: {},
          };
          dispatch(DeleteKmlFeatureAction(feature));
          dispatch(UpdateKmlFeatureAction(newFeature));
          SetMode('direct_select', { featureId: newFeature.id as string })(dispatch);
        }
      } else {
        dispatch(ClearDrawnKmlAction());
        dispatch(ActionCreators.clearHistory());
      }
    } else {
      const { kmls, selectedKmlId } = getState().kml.present;

      const orders = getOrders(getState());
      const order = orders.activeOrder;
      const { featureCollection } = getState().kml.present.kmls[order._id];
      const newFeatures = featureCollection.features.filter((f) => f.id !== selectedKmlId);
      if (newFeatures.length === 0) {
        const acres = calculateAcres(featureCollection);
        window?.pendo?.track('Delete KML', { acres: acres?.toFixed(2) });
        RemoveKml()(dispatch, getState);
        dispatch(ResetBufferStateAction());
        dispatch(SetShowKmlToolbarAction(false));
        dispatch(ActionCreators.clearHistory());
      } else {
        const newKmls = { ...kmls, [order._id]: { ...kmls[order._id], featureCollection: { type: 'FeatureCollection', features: newFeatures } } };
        dispatch(OverwriteKmlsAction(newKmls));
        const kml = ToKml(newKmls[order._id].featureCollection);
        const blob = new Blob([kml], { type: 'application/vnd.google-earth.kml+xml' });
        const fileName = (order.boundaryFile?.split('/').pop());
        const fileBlob = new File([blob], fileName);
        await FileUploadStartThunk([fileBlob], true, false, false, true)(dispatch, getState);
      }
    }
  };

export const SaveDrawnKmlLine = (feature?: GeoJSON.Feature<GeoJSON.LineString>) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const features = getState().map.draw.present.currentKml?.features.filter((f) => f.geometry.type === 'LineString');
    const { addToExistingKml } = getState().map.draw.present;
    const featureCollection: GeoJSON.FeatureCollection = {
      type: 'FeatureCollection',
      features: features || [],
    };
    dispatch(UploadingDrawnKmlAction(true));
    await Save(featureCollection, 'line', true, addToExistingKml)(dispatch, getState);
    dispatch(SetShowKmlToolbarAction(false));
  };

export const SaveDrawnKmlPolygon = () =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const drawnFeatureCollection = getState().map.draw.present.currentKml;
    let kmlType = 'polygon';
    if (drawnFeatureCollection.features.length > 0) {
      if (drawnFeatureCollection.features.length > 1) {
        kmlType = 'multiple polygons';
      }
      dispatch(UploadingDrawnKmlAction(true));
      await Save(drawnFeatureCollection, kmlType, false)(dispatch, getState);
    }
    dispatch(ResetKmlDrawAction());
    dispatch(SetShowKmlToolbarAction(false));
  };
