// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const useStyles = (theme: any) => ({
  kmlToolbar: {
    backgroundColor: '#fff',
    padding: '8px 16px',
  },
  uploadKmlProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-8px',
    marginLeft: '-8px',
    color: '#fff',
  },
  option: {
    padding: '8px',
    boxShadow: 'none',
    borderRadius: '0px',
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'auto',
    },
  },
  icon: {
    height: '30px',
    width: '30px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cancelButton: {
    marginRight: '15px',
  },
});

export default useStyles;
