// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { ActionCreators } from 'redux-undo';
import { kml } from '@tmcw/togeojson';
import { API_URL } from 'Config';
import GenerateGuid from 'Utils/guid';
import { getFile, getJson, postJson } from 'Utils/http';
import type { RootState } from 'Store';
import { GetOrdersThunk } from 'Features/order/orderThunk';
import { SetViewportAction } from 'Features/map/mapCommonActions';
import { getOrders } from '../order/orderSelectors';
import { UpdateOrderAction } from '../order/orderActions';
import { ResetKmlDrawAction } from '../map/mapDrawActions';
import { SetShowKmlToolbarAction } from '../project/projectActions';
import {
  SetKmlAction,
  LoadKmlsStartAction,
  LoadKmlsSuccessAction,
  SetKmlErrorAction,
  CreateKmlBufferStartAction,
  CreateKmlBufferSuccessAction,
  CreateKmlBufferFailureAction,
  SetLineStringUploadAction,
  SetShowBufferInfoAction,
  AdminToggleKmlVisibilityAction,
  SetRoadNetworkClickedAction,
  CreateRoadNetworkKmlBufferStartAction,
  CreateRoadNetworkKmlBufferSuccessAction,
  CreateRoadNetworkKmlBufferFailureAction,
} from './kmlActions';

const readFromText = (text: string) => {
  const emptyCollection: GeoJSON.FeatureCollection<GeoJSON.Polygon> = {
    type: 'FeatureCollection',
    features: [],
  };
  const parser = new DOMParser();

  const parsedDocument = parser.parseFromString(text, 'text/xml');
  const geoJson = kml(parsedDocument);
  geoJson.features.forEach((feature) => {
    feature.id = GenerateGuid();
  });

  return geoJson || emptyCollection;
};

export const SetKmlThunk = (featureCollection: GeoJSON.FeatureCollection, featureId?:string) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const emptyCollection: GeoJSON.FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };

    const orders = getOrders(getState());
    const { activeOrder } = orders;

    const kmlBoundary = featureCollection || emptyCollection;
    kmlBoundary.features.map((feature) => ({
      ...feature,
      id: GenerateGuid(),
    }));

    dispatch(SetKmlAction({ orderId: activeOrder._id, kml: kmlBoundary }));
  };

export const ReadFromTextThunk = (text: string) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const geoJson = readFromText(text);

    SetKmlThunk(geoJson)(dispatch, getState);
  };

export const LoadKmlsThunk = () =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const emptyCollection: GeoJSON.FeatureCollection<GeoJSON.Polygon> = {
      type: 'FeatureCollection',
      features: [],
    };
    dispatch(LoadKmlsStartAction());
    const orders = getOrders(getState());

    const ordersWithoutKml = orders.projectOrders.filter((order) => !order.boundaryFile);
    const ordersWithKml = orders.projectOrders.filter((order) => order.boundaryFile);
    const { token } = getState().auth;
    const { projectId } = getState().project;
    let orgId;
    if (!_ADMIN_ && 'opsTrainer' in getState().auth.resources) {
      const project = getState().project?.list?.find((p) => p._id === projectId);
      orgId = project?.ownerOrganization;
    } else {
      orgId = _ADMIN_ ? getState().admin.orgId : getState().auth.user.ownerOrganization;
    }
    const promiseArray = [];
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < ordersWithKml.length; i += 1) {
      const filename = ordersWithKml[i].boundaryFile.split('/').pop();
      const url = `${API_URL}/readKML/${orgId}/${projectId}/${ordersWithKml[i]._id}/${filename}`;
      const res = await getJson<string>(url, token);
      promiseArray.push(getFile(res.data));
    }
    const fileResult = await Promise.all(promiseArray);
    const anyFailed = fileResult.some((r) => !r.success);
    if (anyFailed) return;

    const result = [
      ...ordersWithKml
        .map((order, index) => ({ orderId: order._id, kml: readFromText(fileResult[index].data) })),
      ...ordersWithoutKml
        .map((o) => ({ orderId: o._id, kml: emptyCollection })),
    ];
    dispatch(LoadKmlsSuccessAction(result));
  };

export const DownloadKMLThunk = (orderId: string, filename: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { user, token } = getState().auth;
    const { projectId } = getState().project;
    let orgId;
    const isAdmin = Boolean(user.roles && user.roles.includes('admin'));
    if (isAdmin) {
      orgId = getState().admin.orgId;
    } else {
      orgId = getState().auth.user.ownerOrganization;
    }

    const url = `${API_URL}/downloadKML/${orgId}/${projectId}/${orderId}/${filename}`;
    const result = await getJson<string>(url, token);

    if (result.success) {
      window.open(result.data, '_self');
    }
  };

export const GenerateKmlBufferThunk = (road: boolean = false) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { user, token } = getState().auth;
    const { projectId } = getState().project;
    let orgId;
    if (_ADMIN_) {
      orgId = getState().admin.orgId;
    } else {
      orgId = user.ownerOrganization;
    }
    const orders = getOrders(getState());
    const order = orders.activeOrder;
    const orderId = order._id;
    const { bufferSize } = getState().kml.present;
    const data = {
      radius: bufferSize,
      road,
    };
    const url = `${API_URL}/bufferKML/${orgId}/${projectId}/${orderId}`;

    dispatch(CreateKmlBufferStartAction());
    const result = await postJson<any>(url, data, token);

    if (result.success) {
      const resultOrder = result.data;
      const filename = resultOrder.boundaryFile.split('/').pop();
      const readKmlUrl = `${API_URL}/readKML/${orgId}/${projectId}/${resultOrder._id}/${filename}`;
      const kmlResponse = await getJson<string>(readKmlUrl, token);
      if (kmlResponse.success) {
        const fileBlobResult = await getFile(kmlResponse.data);
        ReadFromTextThunk(fileBlobResult.data)(dispatch, getState);
        dispatch(ResetKmlDrawAction());
        dispatch(UpdateOrderAction(resultOrder as IOrder));
        dispatch(CreateKmlBufferSuccessAction());
        dispatch(SetLineStringUploadAction(false));
        if (!_ADMIN_) {
          dispatch(SetShowKmlToolbarAction(false));
          dispatch(SetShowBufferInfoAction(true));
        }

        dispatch(ActionCreators.clearHistory());
        await GetOrdersThunk(projectId)(dispatch, getState);
        ZoomToKml()(dispatch, getState);
      }
      return;
    }

    // Handle error in bufferKML API or readKML API
    dispatch(CreateKmlBufferFailureAction());
    dispatch(SetKmlErrorAction(true));
    dispatch(SetLineStringUploadAction(false));
    dispatch(SetShowKmlToolbarAction(false));
  };

export const GenerateRoadNetworkKmlBufferThunk =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    const { user, token } = getState().auth;
    const { projectId } = getState().project;
    let orgId;
    if (_ADMIN_) {
      orgId = getState().admin.orgId;
    } else {
      orgId = user.ownerOrganization;
    }
    const orders = getOrders(getState());
    const order = orders.activeOrder;
    const orderId = order._id;
    const { bufferSize } = getState().kml.present;
    const data = {
      radius: bufferSize,
    };
    const url = `${API_URL}/roadBufferKML/${orgId}/${projectId}/${orderId}`;

    dispatch(CreateRoadNetworkKmlBufferStartAction());
    const result = await postJson<any>(url, data, token);

    if (result.success) {
      const resultOrder = result.data;
      window?.pendo?.track('Road Network Requested', { network_length: resultOrder?.kmlLength });
      const filename = resultOrder.boundaryFile.split('/').pop();
      const readKmlUrl = `${API_URL}/readKML/${orgId}/${projectId}/${resultOrder._id}/${filename}`;
      const kmlResponse = await getJson<string>(readKmlUrl, token);
      if (kmlResponse.success) {
        const fileBlobResult = await getFile(kmlResponse.data);
        ReadFromTextThunk(fileBlobResult.data)(dispatch, getState);
        dispatch(ResetKmlDrawAction());
        dispatch(UpdateOrderAction(resultOrder as IOrder));
        dispatch(CreateRoadNetworkKmlBufferSuccessAction());
        dispatch(SetRoadNetworkClickedAction(false));
        dispatch(ActionCreators.clearHistory());
        GetOrdersThunk(projectId)(dispatch, getState);
      }
      if (!_ADMIN_) {
        dispatch(SetShowBufferInfoAction(true));
      }

      return;
    }

    // Handle error in bufferKML API or readKML API
    dispatch(CreateRoadNetworkKmlBufferFailureAction());
    dispatch(SetKmlErrorAction(true));
  };
export const SetLineStringUploadThunk = (lineStringUpload: boolean) => (dispatch: Dispatch) => {
  dispatch(SetLineStringUploadAction(lineStringUpload));
};

export const SetRoadNetworkThunk = (getRoadNetwork: boolean) => (dispatch: Dispatch) => {
  dispatch(SetRoadNetworkClickedAction(getRoadNetwork));
};

export const AdminToggleKml = (id: string) => (dispatch: Dispatch) => dispatch(AdminToggleKmlVisibilityAction(id));

export const ZoomToKml = () => (dispatch: Dispatch, getState: () => RootState) => {
  const { bbox } = getState().kml.present;

  if (!bbox || bbox.length === 0) {
    return;
  }

  const [minLng, minLat, maxLng, maxLat] = bbox;
  const centerLng = (minLng + maxLng) / 2;
  const centerLat = (minLat + maxLat) / 2;

  // Calculate the zoom level
  const lngDiff = maxLng - minLng;
  const latDiff = maxLat - minLat;
  const maxDiff = Math.max(lngDiff, latDiff);
  const zoom = Math.floor(10 - Math.log(maxDiff) / Math.log(2));

  dispatch(SetViewportAction({ center: [centerLng, centerLat], zoom }));
};
