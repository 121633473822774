// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/system';
import { Typography } from '@mui/material';
import { useAppSelector } from 'Hooks';
import { RootState } from 'Store';
import { getOrders } from 'Features/order/orderSelectors';
import stylesDef from './styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ParentLayerItem } from './ParentLayerItem';

interface LayerSelectProps {
  bundleType: string;
  onAddOnsClick: (item: IDeliverable, checked: boolean, typeFactor3D?: boolean) => void;
  selectAdditionalDeliverable: (data: IDeliverable, checked: boolean, typeFactor3D?:boolean) => void;
}

export const LayerSelect = ({ bundleType, onAddOnsClick, selectAdditionalDeliverable }: LayerSelectProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { additionalDeliverables, selectedBundle, availableBundles } = useAppSelector(
    (state) => state.bundle,
  );

  const { name: bundleName, bundleId, type } = selectedBundle || {};
  const { activeOrder } = useSelector((state: RootState) => getOrders(state));
  const { telecomLayerAttributions } = useFlags();
  const utilitiesBundle = bundleType === 'utilities';

  const ugAlaCarte = availableBundles.find((b) => b.name === 'Underground A-la-carte');

  const ugAlaCarteBoolean = bundleName === 'Underground A-la-carte';
  const utilitiesDeliverables = additionalDeliverables.filter((d) => d.subtype === 'Utilities');
  const roadsAndSidewalkDeliverables = additionalDeliverables.filter((d) => d.subtype === 'Roads & Sidewalk');
  const vegetationAndLandscapeDeliverables = additionalDeliverables.filter((d) => d.subtype === 'Vegetation & Landscape');
  const topographyLayerDeliverables = additionalDeliverables.filter((d) => d.subtype === 'Topography' && d.type === 'layer');
  const administrativeDeliverables = additionalDeliverables.filter((d) => d.subtype === 'Administrative & Annotations');
  const planimetricsDeliverables = additionalDeliverables.filter((d) => d.subtype === 'Planimetrics');
  const measurementsDeliverables = additionalDeliverables.filter((d) => d.subtype === 'Measurements');
  const wallsAndFencesDeliverables = additionalDeliverables.filter((d) => d.subtype === 'Walls & Fences');
  const additionalLayersDeliverables = additionalDeliverables.filter((d) => d.subtype === 'Additional Layers');

  // Utilities Add-ons
  const ugBasicAddonDeliverables = utilitiesBundle ? additionalDeliverables.filter((d) => d.subtype === 'Underground Basic Add-Ons') : [];
  const ugHeavyAddonDeliverables = utilitiesBundle ? additionalDeliverables.filter((d) => d.subtype === 'UG Heavy Add-On') : [];
  const utilityPolesBasicDeliverables = utilitiesBundle ? additionalDeliverables.filter((d) => d.subtype === 'Utility Poles - Basic') : [];
  const heavyAddonAttributionsDeliverables = utilitiesBundle && telecomLayerAttributions ? additionalDeliverables.filter((d) => d.subtype === 'UG Utility Heavy Add-on Attributions') : [];
  const aerialAttributionsDeliverables = utilitiesBundle && telecomLayerAttributions ? additionalDeliverables.filter((d) => d.subtype === 'Aerial 3D Add-on Attributions') : [];
  const aerialMiscWiresDeliverables = utilitiesBundle ? additionalDeliverables.filter((d) => d.subtype === 'Misc. Wires') : [];
  const aerialBuiltInfrastructureDeliverables = utilitiesBundle ? additionalDeliverables.filter((d) => d.subtype === 'Built Infrastructure') : [];

  return (
    <Box sx={bundleName === 'Topo' && styles.unavailableDeliverables}>
      <Typography sx={styles.addonsHeading} variant="h3">
        Layers
      </Typography>
      {bundleName === 'Topo' ? (
        <Typography sx={styles.noDeliverableMessage}>
          Please select the 2D + TOPO bundle to add 2D features at checkout
        </Typography>
      ) : <Typography sx={styles.description}>Please select any additional layers you’d like to be drafted in your project.</Typography> }
      {(bundleId === 4 || bundleId === 5) && (
        <Typography sx={styles.noDeliverableMessage}>
          Please select the 2D + TOPO bundle to add the disabled layers at checkout
        </Typography>
      )}
      {ugBasicAddonDeliverables.length > 0 && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Underground Basic Add-Ons
          </Typography>
          <ParentLayerItem
            items={ugBasicAddonDeliverables}
            activeOrder={activeOrder}
            ugAlaCarte={ugAlaCarte}
            ugAlaCarteBoolean={ugAlaCarteBoolean}
            selectAdditionalDeliverable={onAddOnsClick}
            isUnavailable={(item: IDeliverable) => type === 'utilities' && !item.selectable}
          />
        </Box>
      )}
      {ugHeavyAddonDeliverables.length > 0 && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            UG Heavy Add-on
          </Typography>
          <ParentLayerItem
            items={ugHeavyAddonDeliverables}
            activeOrder={activeOrder}
            ugAlaCarte={ugAlaCarte}
            ugAlaCarteBoolean={ugAlaCarteBoolean}
            selectAdditionalDeliverable={onAddOnsClick}
            isUnavailable={(item: IDeliverable) => type === 'utilities' && !item.selectable}
          />
        </Box>
      )}
      {utilityPolesBasicDeliverables.length > 0 && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Utility Poles - Basic
          </Typography>
          <ParentLayerItem
            items={utilityPolesBasicDeliverables}
            activeOrder={activeOrder}
            ugAlaCarte={ugAlaCarte}
            ugAlaCarteBoolean={ugAlaCarteBoolean}
            selectAdditionalDeliverable={onAddOnsClick}
            isUnavailable={(item: IDeliverable) => type === 'utilities' && !item.selectable}
          />
        </Box>
      )}
      {heavyAddonAttributionsDeliverables.length > 0 && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Utility Heavy Layer Attributes
          </Typography>
          <ParentLayerItem
            items={heavyAddonAttributionsDeliverables}
            activeOrder={activeOrder}
            ugAlaCarte={ugAlaCarte}
            ugAlaCarteBoolean={ugAlaCarteBoolean}
            selectAdditionalDeliverable={onAddOnsClick}
            isUnavailable={(item: IDeliverable) => type === 'utilities' && !item.selectable}
          />
        </Box>
      )}
      {aerialMiscWiresDeliverables.length > 0 && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Misc. Wires
          </Typography>
          <ParentLayerItem
            items={aerialMiscWiresDeliverables}
            activeOrder={activeOrder}
            ugAlaCarte={ugAlaCarte}
            ugAlaCarteBoolean={ugAlaCarteBoolean}
            selectAdditionalDeliverable={onAddOnsClick}
            isUnavailable={(item: IDeliverable) => type === 'utilities' && !item.selectable}
          />
        </Box>
      )}
      {aerialBuiltInfrastructureDeliverables.length > 0 && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Built Infrastructure
          </Typography>
          <ParentLayerItem
            items={aerialBuiltInfrastructureDeliverables}
            activeOrder={activeOrder}
            ugAlaCarte={ugAlaCarte}
            ugAlaCarteBoolean={ugAlaCarteBoolean}
            selectAdditionalDeliverable={onAddOnsClick}
            isUnavailable={(item: IDeliverable) => !item.selectable}
          />
        </Box>
      )}
      {aerialAttributionsDeliverables.length > 0 && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Aerial 3D Add-on Attributions
          </Typography>
          <ParentLayerItem
            items={aerialAttributionsDeliverables}
            activeOrder={activeOrder}
            ugAlaCarte={ugAlaCarte}
            ugAlaCarteBoolean={ugAlaCarteBoolean}
            selectAdditionalDeliverable={onAddOnsClick}
            isUnavailable={(item: IDeliverable) => !item.selectable}
          />
        </Box>
      )}
      {utilitiesDeliverables.length > 0 && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Utilities
          </Typography>
          <ParentLayerItem
            items={utilitiesDeliverables}
            activeOrder={activeOrder}
            ugAlaCarte={ugAlaCarte}
            ugAlaCarteBoolean={ugAlaCarteBoolean}
            selectAdditionalDeliverable={onAddOnsClick}
            isUnavailable={(item: IDeliverable) => type === 'utilities' && !item.selectable}
          />
        </Box>
      )}
      {roadsAndSidewalkDeliverables.length > 0 && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Road & Sidewalk
          </Typography>
          <ParentLayerItem
            items={roadsAndSidewalkDeliverables}
            activeOrder={activeOrder}
            ugAlaCarte={ugAlaCarte}
            ugAlaCarteBoolean={ugAlaCarteBoolean}
            selectAdditionalDeliverable={onAddOnsClick}
            isUnavailable={(item: IDeliverable) => type === 'utilities' && !item.selectable}
          />
        </Box>
      ) }
      {vegetationAndLandscapeDeliverables.length > 0 && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Vegetation & Landscape
          </Typography>
          <ParentLayerItem
            items={vegetationAndLandscapeDeliverables}
            activeOrder={activeOrder}
            ugAlaCarte={ugAlaCarte}
            ugAlaCarteBoolean={ugAlaCarteBoolean}
            selectAdditionalDeliverable={onAddOnsClick}
            isUnavailable={(item: IDeliverable) => type === 'utilities' && !item.selectable || (bundleName === '3D Feature Elevation' && !(item.deliverableId === 39))}
          />
        </Box>
      ) }
      {topographyLayerDeliverables.length > 0 && (
        <Box sx={bundleName === '2D' && styles.unavailableDeliverables}>
          <Typography sx={styles.addonsHeading} variant="h4">
            Topography
          </Typography>
          {bundleName === '2D' && (
            <Typography sx={styles.noDeliverableMessage}>
              Please select the 2D + TOPO bundle to add Topography features at checkout
            </Typography>
          )}
          <ParentLayerItem
            items={topographyLayerDeliverables}
            activeOrder={activeOrder}
            ugAlaCarte={ugAlaCarte}
            ugAlaCarteBoolean={ugAlaCarteBoolean}
            selectAdditionalDeliverable={onAddOnsClick}
            isUnavailable={(item: IDeliverable) => type === 'utilities' && !item.selectable}
          />
        </Box>
      ) }
      {administrativeDeliverables.length > 0 && (
        <Box sx={(bundleId === 0 || bundleId === 5) && styles.unavailableDeliverables}>
          <Typography sx={styles.addonsHeading} variant="h4">
            Administrative & Annotations
          </Typography>
          {(bundleId === 0 || bundleId === 5) && (
            <Typography sx={styles.noDeliverableMessage}>
              Please select the 2D + TOPO bundle to add Administrative & Annotations features at checkout
            </Typography>
          )}
          <ParentLayerItem
            items={administrativeDeliverables}
            activeOrder={activeOrder}
            ugAlaCarte={ugAlaCarte}
            ugAlaCarteBoolean={ugAlaCarteBoolean}
            selectAdditionalDeliverable={onAddOnsClick}
            isUnavailable={(item: IDeliverable) => type === 'utilities' && !item.selectable}
          />
        </Box>
      )}
      {planimetricsDeliverables.length > 0 && (
        <Box sx={bundleId === 4 && styles.unavailableDeliverables}>
          <Typography sx={styles.addonsHeading} variant="h4">
            Planimetrics
          </Typography>
          <ParentLayerItem
            items={planimetricsDeliverables}
            activeOrder={activeOrder}
            ugAlaCarte={ugAlaCarte}
            ugAlaCarteBoolean={ugAlaCarteBoolean}
            selectAdditionalDeliverable={onAddOnsClick}
            isUnavailable={(item: IDeliverable) => type !== 'utilities' && !item.selectable || (bundleName === '3D Feature Elevation' && !(item.deliverableId === 21))}
          />
        </Box>
      )}
      {measurementsDeliverables.length > 0 && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Measurements
          </Typography>
          <ParentLayerItem
            items={measurementsDeliverables}
            activeOrder={activeOrder}
            ugAlaCarte={ugAlaCarte}
            ugAlaCarteBoolean={ugAlaCarteBoolean}
            selectAdditionalDeliverable={onAddOnsClick}
            isUnavailable={(item: IDeliverable) => type === 'utilities' && !item.selectable}
          />
        </Box>
      ) }
      {wallsAndFencesDeliverables.length > 0 && (
        <Box sx={bundleName === '3D Feature Elevation' && styles.unavailableDeliverables}>
          <Typography sx={styles.addonsHeading} variant="h4">
            Walls & Fences
          </Typography>
          <ParentLayerItem
            items={wallsAndFencesDeliverables}
            activeOrder={activeOrder}
            ugAlaCarte={ugAlaCarte}
            ugAlaCarteBoolean={ugAlaCarteBoolean}
            selectAdditionalDeliverable={onAddOnsClick}
            isUnavailable={(item: IDeliverable) => type === 'utilities' && !item.selectable}
          />
        </Box>
      ) }
      {additionalLayersDeliverables.length > 0 && (
        <Box sx={bundleName === '2D' && styles.unavailableDeliverables}>
          <Typography sx={styles.addonsHeading} variant="h4">
            Additional Layers
          </Typography>
          <ParentLayerItem
            items={additionalDeliverables}
            activeOrder={activeOrder}
            ugAlaCarte={ugAlaCarte}
            ugAlaCarteBoolean={ugAlaCarteBoolean}
            selectAdditionalDeliverable={onAddOnsClick}
            isUnavailable={(item: IDeliverable) => type === 'utilities' && !item.selectable || (bundleId === 5 && item.deliverableId === 16)}
          />
        </Box>
      ) }
    </Box>
  );
};

export default LayerSelect;
