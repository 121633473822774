// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Box, useTheme } from '@mui/system';
import { Typography, Divider, Button } from '@mui/material';
import { deliverableCost } from 'Utils/deliverables';
import { useAppSelector } from 'Hooks';
import type { RootState } from 'Store';
import { useSelector } from 'react-redux';
import ErrorIcon from '@mui/icons-material/Error';
import { getOrders } from 'Features/order/orderSelectors';
import { selectAvailableTeams } from 'Features/account/accountSelectors';
import EditOrder from './EditOrder';
import stylesDef from './styles';
import PaymentConfirmation from './PaymentConfirmation';
import IncludedAddOns from '../Package/BundleSelect/IncludedAddOns';

interface ConfirmColumnProps {
  setPage: (arg0: number) => void;
  additonalCharges: number;
}

const ConfirmColumn = ({ setPage, additonalCharges }: ConfirmColumnProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const bundleBaseLayers: any[] = [];
  const bundleBaseDeliverables: any[] = [];
  const extraLayers: IDeliverable[] = [];
  const extraDeliverables: IDeliverable[] = [];
  const { bundleDeliverables, selectedAdditionals, selectedBundle } = useAppSelector(
    (state) => state.bundle,
  );
  const { placeOrderSuccess } = useAppSelector(
    (state) => state.order,
  );
  const { activeOrder } = useSelector((state: RootState) => getOrders(state));
  const { referenceId, acreage, team, ownerProject: projectId } = activeOrder;
  const { availableTeams } = useSelector((state: RootState) => selectAvailableTeams(state));
  const currentTeam = availableTeams?.find((t) => t._id === team);
  const expressAIBundle = selectedBundle?.bundleId === 9;

  bundleDeliverables.forEach((del) => {
    if (del.type === 'layer') {
      bundleBaseLayers.push(del);
    } else {
      bundleBaseDeliverables.push(del);
    }
  });

  selectedAdditionals.forEach((del) => {
    if (del.type === 'layer') {
      extraLayers.push(del);
    } else {
      extraDeliverables.push(del);
    }
  });

  return (
    <Box sx={styles.confirmColumn}>
      {placeOrderSuccess ? (
        <div>
          <Typography sx={styles.pageTitle} variant="h1">Thank you for your Order!</Typography>
          {expressAIBundle ? (
            <>
              <Typography variant="body1" sx={styles.confirmText}>Your Express AI project was successfully submitted for processing on AirWorks. The file you receive back will be 100% AI generated, and so will not go through any human-in-the-loop quality controls that are included in other AirWorks products. We will notify you when your map is ready within 24 hours. Some maps are ready even sooner.</Typography>
              <Typography variant="body1" sx={styles.confirmText}>
                For other questions, please feel free to reach out at
                {' '}
                <a style={styles.emailTag} href="mailto:support@airworks.io">support@airworks.io.</a>
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="body1" sx={styles.confirmText}>Your project has successfully begun processing on AirWorks. Our process includes autonomous drafting, quality control and final delivery.</Typography>
              <Typography variant="body1" sx={styles.confirmText}>
                We will notify you when your plan is ready. If you need your project faster, please don't hesitate to reach out. For this or other questions, you can reach us through chat, or at
                {' '}
                <a style={styles.emailTag} href="mailto:support@airworks.io">support@airworks.io.</a>
              </Typography>
            </>
          )}
        </div>
      )
        : (
          <>
            <Typography sx={styles.pageTitle} variant="h1">Confirm and Process</Typography>
            <EditOrder />
            <Divider sx={styles.divider} />
            <Box sx={styles.deliverableList}>
              <Typography variant="body1">Team</Typography>
              <Typography variant="body1">{currentTeam?.name || 'N/A'}</Typography>
              <Typography variant="body1">Reference Id</Typography>
              <Typography variant="body1">{referenceId || 'N/A'}</Typography>
            </Box>
            <Box sx={styles.section}>
              <Typography sx={styles.sectionTitle} variant="h3">Deliverables and Add-Ons</Typography>
              <Button sx={styles.button} onClick={() => setPage(0)}>Edit</Button>
            </Box>
            <Divider sx={styles.divider} />
            <Box sx={styles.outputConfidenceBorder}>
              <ErrorIcon sx={styles.errorIcon} />
                  <Typography sx={styles.outputConfidence}>
                  Check our
                    <a style={styles.anchorTag} target="_blank" href="http://support.airworks.io/Layer-Output-Confidence-Level.2381381633.html" onClick={() => { window?.pendo?.track('Clicked Input Quality Checklist link from the order form', { projectId }); }} rel="noreferrer"> Input Quality Checklist</a> before proceeding.
                </Typography>
            </Box>
            {expressAIBundle && <IncludedAddOns />}
            {!expressAIBundle && (
              <Box sx={styles.deliverableList}>
                <Typography variant="body1">{`Base Layers (${bundleBaseLayers.length} Layers)`}</Typography>
                <Typography variant="body1">Included</Typography>
                <Typography variant="body1">{`Base Deliverables (${bundleBaseDeliverables.length} Items)`}</Typography>
                <Typography variant="body1">Included</Typography>
              </Box>
            )}
            {extraDeliverables.length > 0 && <Typography sx={styles.subSectionTitle} variant="h6">Extra Deliverables</Typography>}
            {extraDeliverables.map((del) => (
              <Box sx={styles.deliverableList}>
                <Typography variant="body1">{del.name}</Typography>
                <Typography variant="body1">{`${deliverableCost(del.typeFactor[0], acreage).toFixed(2)} credits`}</Typography>
              </Box>
            ))}
            {extraLayers.length > 0 && <Typography sx={styles.subSectionTitle} variant="h6">Extra Layers</Typography>}
            {extraLayers.map((del) => (
              <Box sx={styles.deliverableList}>
                <Typography variant="body1">{del.name} {del.is3D ? '(3D)' : ''}</Typography>
                <Typography variant="body1">{` ${deliverableCost(del.is3D ? del.typeFactor[1] : del.typeFactor[0], acreage).toFixed(2)} credits`}</Typography>
              </Box>
            ))}
            {!expressAIBundle && (
              <>
                <Box sx={styles.section}>
                  <Typography sx={styles.sectionTitle} variant="h3">Billing and Payment</Typography>
                  <Button sx={styles.button} onClick={() => setPage(1)}>Edit</Button>
                </Box>
                <Divider sx={styles.divider} />
              </>
            )}
          </>
        )}
      {additonalCharges > 0 ? (
        <PaymentConfirmation />
      ) : (
        !placeOrderSuccess && !expressAIBundle && <Typography variant="body1">No additional charges required</Typography>
      )}
    </Box>
  );
};

export default ConfirmColumn;
